import SearchIcon from '@mui/icons-material/Search';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  InputAdornment,
  Popover,
  Typography,
} from '@mui/material';
import redcheckbox from 'assets/images/redcheckbox.svg';
import CustomInput from 'components/CustomInput/customInput';
import { Loader } from 'components/loader';
import debouce from 'lodash.debounce';
import React from 'react';
import { getAllListData } from 'services/CommonServices';
import { SkillType } from 'types';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { SkillInfo, SkillReponse } from 'utils/type';

type Props = {
  selectSkill: Array<SkillType>;
  setSelectSkill: (src: SkillType[]) => void;
  anchorEl: null | HTMLElement;
  setAnchorEl: (src: null | any) => void;
  id: string;
  addSkills: () => void;
};
export const SkillMenu: React.FC<Props> = (props: Props) => {
  const { setSelectSkill, selectSkill, anchorEl, setAnchorEl, id, addSkills } =
    props;

  const [loading, setLoading] = React.useState(false);
  const [skillList, setSkillList] = React.useState<Array<SkillInfo>>([]);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = React.useCallback(
    (e: HTMLInputElement | any) => {
      setSearchValue(e.target.value);
    },
    [setSearchValue],
  );
  const debouncedResults = React.useMemo(() => {
    return debouce(handleChange, 1000);
  }, [handleChange]);

  const getSkillList = (str: string) => {
    getAllListData(
      MASTER_ENDPOINT.Skills +
        '?sortby=name&order=asc&status=true&primary=true&skip=0&take=50&search=' +
        encodeURIComponent(str),
    )
      .then((resp: SkillReponse) => {
        setLoading(false);
        const capitalizedArrayOfStrings = resp.data.map((e) => ({
          ...e,
          name: e.name.charAt(0)?.toUpperCase() + e.name.slice(1),
        }));
        setSkillList([...capitalizedArrayOfStrings]);
      })
      .catch(() => {
        setSkillList([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    setLoading(true);
    getSkillList(searchValue);
  }, [searchValue]);
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}>
      <Typography component={'div'} className="skil-popup">
        <CustomInput
          className="custom-search skill-search postition-sticky top-0"
          placeHolder="Select skills"
          onChange={debouncedResults}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end" className="icon-postition">
                <SearchIcon className="red-color" />
              </InputAdornment>
            ),
          }}
        />
        <Divider className="postition-sticky top-45" />
        <Typography component={'div'} className="skill-list-height">
          {loading ? (
            <Loader
              pageCenterLoadingHide={true}
              className={'d-flex-ja w-100 h-100'}
            />
          ) : skillList.length ? (
            skillList.map((e) => (
              <Typography component={'div'} key={e.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checkedIcon={<img src={redcheckbox} alt="redcheck" />}
                      checked={selectSkill.some((ids) => ids.id === e.id)}
                      value={e?.id}
                      onChange={(el) => {
                        if (el?.target?.checked) {
                          setSelectSkill([
                            ...selectSkill,
                            { id: e.id, name: e.name, rowId: e.id },
                          ]);
                        } else {
                          setSelectSkill([
                            ...selectSkill.filter(
                              (existId) => existId.rowId !== e?.id,
                            ),
                          ]);
                          console.warn(el?.target?.checked);
                        }
                      }}
                    />
                  }
                  label={e.name}
                />
              </Typography>
            ))
          ) : (
            <Typography
              component={'div'}
              className={'d-flex-ja w-100 h-100 red-color'}>
              No Records Found
            </Typography>
          )}
        </Typography>

        <Typography
          component={'div'}
          className="text-center bottom-0 postition-sticky">
          <Divider />
          <Typography
            component={'button'}
            type={'button'}
            className={'rec-add-btn ad-btn-notes don-btn mt-8 mb-8'}
            onClick={() => {
              addSkills();
              handleClose();
            }}>
            Add
          </Typography>
        </Typography>
      </Typography>
    </Popover>
  );
};
