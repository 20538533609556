import { Box, Typography } from '@mui/material';
import React from 'react';

import { HeaderTab } from '../Analytics/header';
import { AddNotes } from './AddNotes';

type Props = {
  setAnalytics: (src: boolean) => void;
};
export const ListNotes: React.FC<Props> = (props: Props) => {
  const { setAnalytics } = props;
  const [shownNotes, setShownAddNotes] = React.useState(false);
  return (
    <Typography component={'div'}>
      <HeaderTab
        tab="notes"
        setAnalytics={setAnalytics}
        setShownAddNotes={setShownAddNotes}
        shownNotes={shownNotes}
      />
      {shownNotes && <AddNotes closeNotes={() => setShownAddNotes(false)} />}
      <Typography component={'div'} className="body-hight">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((e) => (
          <Box className="notes-card-info mt-12" key={e}>
            <Typography component={'div'} className="info">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry&apos;s standard dummy
              text ever since the 1500s, when an unknown printer took a galley
              of type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s
            </Typography>

            <Typography
              component={'div'}
              className="d-flex-a flex-sb footer-info">
              <Typography component={'div'} className="title">
                Created bY &nbsp;{' '}
                <span className="red-color f-12 ">Anger Westerveli</span>
              </Typography>
              <Typography component={'div'} className="title">
                Created Date &nbsp;{' '}
                <span className="red-color f-12 ">10/08/2024</span>
              </Typography>
            </Typography>
          </Box>
        ))}
      </Typography>
      <Typography component={'div'} className="footer-info">
        <Typography component={'div'} className="resume-btn-footer">
          Next
        </Typography>
      </Typography>
    </Typography>
  );
};
