import { Box, Grid, Modal, Typography } from '@mui/material';
import React from 'react';
import { useAppSelector } from 'store/hooks';
import { MASTER_ENDPOINT } from 'utils/Constant';

import { Analytics } from './Analytics';
import { ListNotes } from './Notes/ListNotes';
import { CandidateList } from './ResumeInfo/CandidateList';
import { CreateCandidate } from './ResumeInfo/CreateCandidate';
import { PdfViewer } from './viewer';

type Props = {
  visible: boolean;
  onClose: (status: string) => void;
  resumeId: string;
  fileId: string;
};

export const ResumeReview: React.FC<Props> = (props: Props) => {
  const { resumeId, fileId, visible, onClose } = props;
  const [selectCandidate, setSelectCandidate] = React.useState<string>('');
  const { userInfo } = useAppSelector((store) => store.userLogin);
  const [analytics, setAnalytics] = React.useState<boolean>(false);

  return (
    <Modal
      open={visible}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box className={'resume-modal'}>
        <Typography id="modal-modal-title" className="d-flex-a flex-sb">
          <Typography
            variant="h6"
            component="h2"
            className="resume-header-name"
            sx={{ transform: 'translateY(-9px)' }}>
            Resume Validation
          </Typography>
          <div
            className="closebtn"
            onClick={() => onClose('')}
            style={{
              cursor: 'pointer',
              transform: 'translateY(-12px)',
              zIndex: 3,
            }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="none"
              viewBox="0 0 18 18">
              <path
                fill="#000"
                d="M17.813.189a.645.645 0 00-.91 0l-7.9 7.9-7.905-7.9a.643.643 0 00-.91.91l7.905 7.9-7.901 7.903a.643.643 0 10.91.91l7.9-7.904 7.905 7.9a.643.643 0 00.906-.906L9.912 8.998l7.901-7.903a.643.643 0 000-.906z"
              />
            </svg>
          </div>
        </Typography>
        <Typography id="modal-modal-description">
          <Grid container spacing={2}>
            <Grid item md={5.5} sm={6}>
              <PdfViewer
                url={`${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.Files}/${fileId}?type=pdf`}
                hideDownload={true}
              />
            </Grid>
            <Grid item md={6.5} sm={6}>
              {userInfo?.roleId === 3 ? (
                <Typography component={'div'}>
                  {selectCandidate ? (
                    <CreateCandidate
                      close={onClose}
                      resumeId={resumeId}
                      candidateId={
                        selectCandidate === 'new' ? null : selectCandidate
                      }
                    />
                  ) : (
                    <CandidateList
                      setSelectCandidate={setSelectCandidate}
                      resumeId={resumeId}
                    />
                  )}
                </Typography>
              ) : userInfo?.roleId === 5 ? (
                <Typography component={'div'}>
                  {analytics ? (
                    <Analytics
                      setAnalytics={setAnalytics}
                      closeModal={onClose}
                    />
                  ) : (
                    <ListNotes setAnalytics={setAnalytics} />
                  )}
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        </Typography>
      </Box>
    </Modal>
  );
};
