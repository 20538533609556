/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react/jsx-no-useless-fragment */
import { Typography } from '@mui/material';
import AnalyticsCheckboxSvg from 'components/SvgAsCode/Analyticscheckboxsvg';
import ResumevaliodationFlagSvg from 'components/SvgAsCode/ResumevalidationFlag';
import React from 'react';

import { HeaderTab } from './header';

type Props = {
  setAnalytics: (src: boolean) => void;
  closeModal?: any;
};
const TabboxComp = ({ dt2, idx, key }: { dt2: any; idx: number; key: any }) => {
  return (
    <>
      {' '}
      <div
        className={`infocontentBox ${
          dt2 && dt2.notmatch ? 'BorderRedAnalytics' : 'BorderGreenAnalytics'
        } ${dt2 && dt2.History ? 'infoHistoryBox' : ''}`}
        key={key}>
        {dt2 && dt2.label ? <div className="label">{dt2.label}</div> : <></>}
        <div className="title">{dt2 && dt2.title ? dt2.title : ''}</div>
        <div className="flagContainer">
          {dt2 && dt2.link && dt2.linkTxt ? (
            <div className="link">{dt2.linkTxt}</div>
          ) : (
            <></>
          )}
          {dt2 && dt2.flagTxt ? (
            <div className="flagtxt">{dt2.flagTxt}</div>
          ) : dt2 && !dt2.History ? (
            <div className="flagIcon">
              <ResumevaliodationFlagSvg Color={dt2.flagColor} />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};
const TopContainer = [
  {
    data: [
      {
        flagTxt: 'YES',
        link: 'Click here to view LinkedIn profile',
        linkTxt: 'Click here to view LinkedIn profile',
        title: 'Was the profile identified',
      },
      {
        flagColor: '#00BA00',

        title: 'Employment/Job Information',
      },
      {
        flagColor: '#00BA00',
        title: 'LinkedIn Account Verification Status',
      },
      {
        flagColor: '#00BA00',
        title: 'Mismatch in Total Experience',
      },
      {
        data: [
          {
            flagColor: '#FF0000',
            notmatch: true,
            title: 'Profile created',
          },
          {
            flagColor: '#FF0000',
            notmatch: true,
            title: 'Personal Details',
          },
          {
            flagColor: '#FF0000',
            notmatch: true,
            title: 'Educational Details',
          },
          {
            flagColor: '#00BA00',
            title: 'Skill match to Resume',
          },
        ],
        subBox: true,
      },
    ],
    social: true,
    title: 'LinkedIn Profile Validation',
  },
  {
    data: [
      {
        flagColor: '#00BA00',
        title: 'Compare skills between JD and Resume',
      },
    ],
    title: 'Job Description (JD) Keywords Analysis',
  },
  {
    nodata: true,
    score: '8/10',
    title: 'Job Description (JD) Keywords Analysis',
  },
  {
    data: [
      {
        data: [
          {
            title: 'Profile created',
          },
          {
            title: 'Personal Details',
          },
          {
            title: 'Educational Details',
          },
          {
            title: 'Skill match to Resume',
          },
        ],
        subBox: true,
      },
    ],
    title: 'Document History Analysis',
  },
  {
    data: [
      {
        History: true,
        data: [
          {
            History: true,
            label: 'Last Updated By',
            title: 'Maduvanthan',
          },
          {
            History: true,
            label: 'Last Updated On',
            title: '12/07/2014',
          },
        ],
      },
    ],
    title: 'Document History Analysis',
  },
];

export const Analytics: React.FC<Props> = (props: Props) => {
  const { setAnalytics, closeModal } = props;
  const [checked, setchecked] = React.useState<boolean>(true);
  const [done, setdone] = React.useState(false);
  function handleDone() {
    setdone((p) => !p);
  }
  const handleChecked = () => setchecked((p) => !p);
  return (
    <Typography component={'div'}>
      <HeaderTab
        tab="analytics"
        setAnalytics={setAnalytics}
        hideAddNotes={true}
        FlagDropdown={true}
      />
      <Typography
        component={'div'}
        className="body-hight body-hight2"
        style={done ? { height: '45vh', opacity: 0.12 } : {}}>
        {TopContainer && TopContainer.length > 0 ? (
          TopContainer.map((dt: any, idx: any) => {
            return (
              <>
                {' '}
                <div
                  className={`AnalyticalDetailscontainer ${
                    dt && dt.nodata ? 'AnalyticalDetailscontainer2' : ''
                  }`}
                  key={idx}>
                  <div className="heading">
                    <div className="headingtxt">
                      {dt && dt.title ? dt.title : ''}
                    </div>
                    {dt && dt.score ? (
                      <div className="score">{dt.score}</div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="infocontainer">
                    {dt && dt.data ? (
                      dt.data.map((dt2, idx2) => {
                        if (
                          dt2 &&
                          dt2.subBox &&
                          dt2.data &&
                          dt2.data.length > 0
                        ) {
                          return (
                            <div className="subbox" key={idx2}>
                              {dt2.data.map((dt3: any, idx3: number) => {
                                return (
                                  <TabboxComp dt2={dt3} idx={idx3} key={idx3} />
                                );
                              })}
                            </div>
                          );
                        }
                        if (
                          dt2 &&
                          dt2.History &&
                          dt2.data &&
                          dt2.data.length > 0
                        ) {
                          return (
                            <div className="history" key={idx2}>
                              {dt2.data.map((dt3: any, idx3: number) => {
                                return (
                                  <TabboxComp dt2={dt3} idx={idx3} key={idx3} />
                                );
                              })}
                            </div>
                          );
                        }

                        return <TabboxComp dt2={dt2} idx={idx2} key={idx2} />;
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </>
            );
          })
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <></>
        )}
      </Typography>
      {done ? (
        <div className="reasoncontainer">
          <div className="inputContainer">
            <div className="label">Reason for accept / Reject</div>
            <textarea
              className="height-164"
              name=""
              id=""
              cols={30}
              rows={30}
              value={
                'Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus.'
              }
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      <Typography component={'div'} className="Analyticsfooter">
        {done ? (
          <>
            <div className="reasonBtnContainer" style={{ width: '100%' }}>
              <div className="btn bt1" onClick={handleDone}>
                cancel
              </div>
              <div className="btn bt2" onClick={closeModal}>
                Save
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="footerFirstBoc">
              <div className="txt">
                Based on the above analysis, do you wish to Accept the resume
                for the Job?
              </div>
              <div className="choice">
                <div className="checkbox" onClick={handleChecked}>
                  <span className="svg">
                    <AnalyticsCheckboxSvg Selected={checked} />
                  </span>
                  <span className="txt2">Accept</span>
                </div>
                <div className="checkbox" onClick={handleChecked}>
                  <span className="svg">
                    <AnalyticsCheckboxSvg Selected={!checked} />
                  </span>
                  <span className="txt2">Reject</span>
                </div>
              </div>
            </div>
            <div className="btn" onClick={handleDone}>
              Done
            </div>
          </>
        )}
      </Typography>
    </Typography>
  );
};
