import SearchIcon from '@mui/icons-material/Search';
import { Box, Grid, InputAdornment, Modal, Typography } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import { Loader } from 'components/loader';
import debouce from 'lodash.debounce';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { MASTER_ENDPOINT, RESUME_ENDPOINT } from 'utils/Constant';
import {
  MatchedProfileInfo,
  MatchedProfileParams,
  MatchedProfileResponse,
} from 'utils/type';

import { PdfViewer } from '../viewer';

type Props = {
  setSelectCandidate: (src: string) => void;
  resumeId: string;
};
type cardProps = {
  setSelectCandidate: (src: string) => void;
  setResumeView: (src: { candidateId: string; open: boolean }) => void;
  info: MatchedProfileInfo;
};
const CardDetails: React.FC<cardProps> = (props: cardProps) => {
  const { setSelectCandidate, info, setResumeView } = props;
  return (
    <Box className="card-info ">
      <Grid container spacing={2}>
        <Grid item md={2}>
          <span className="title">Name</span>
        </Grid>
        <Grid item md={10}>
          <span className="Value red-color">
            {info?.firstName} {info?.middleName} {info?.lastName}
          </span>
        </Grid>

        <Grid item md={2}>
          <span className="title">Job Title</span>
        </Grid>
        <Grid item md={10}>
          <span className="Value"> {info?.jobTitle || '-'}</span>
        </Grid>

        <Grid item md={2}>
          <span className="title">Email</span>
        </Grid>
        <Grid item md={10}>
          <span className="Value">{info?.email || '-'}</span>
        </Grid>

        <Grid item md={2}>
          <span className="title">Phone</span>
        </Grid>
        <Grid item md={4}>
          <span className="Value">{info?.mobileNumber}</span>
        </Grid>
        <Grid item md={6} className="d-flex-jae g-7">
          <Typography
            component={'div'}
            className="view-resume"
            onClick={() =>
              setResumeView({ candidateId: info?.id, open: true })
            }>
            View Resume
          </Typography>
          <Typography
            component={'div'}
            className="btn-update-resume"
            onClick={() => setSelectCandidate(info.id)}>
            Update Resume
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
export const CandidateList: React.FC<Props> = (props: Props) => {
  const { setSelectCandidate, resumeId } = props;

  const { userInfo } = useAppSelector((store) => store.userLogin);
  const [hasMoreResults, setHasMoreResults] = React.useState(false);
  const [getMoreLoader, setGetMoreLoader] = React.useState(false);
  const [loader, setLoader] = React.useState<boolean>(true);
  const [matchedProfile, setMatchedProfile] =
    React.useState<MatchedProfileResponse | null>();
  const [resumeView, setResumeView] = React.useState<{
    candidateId: string;
    open: boolean;
  }>({
    candidateId: '',
    open: false,
  });
  const [params, setParams] = React.useState<MatchedProfileParams>({
    order: 'asc',
    resumeId: '',
    search: '',
    skip: 0,
    sortby: '',
    take: 50,
  });

  const onClose = () => {
    setResumeView((pre) => ({
      ...pre,
      candidateId: '',
      open: false,
    }));
  };
  const handleChange = React.useCallback(
    (e: HTMLInputElement | any) => {
      setLoader(true);
      setParams((pre) => ({
        ...pre,
        search: e.target.value,
        skip: 0,
        take: 50,
      }));
    },
    [setParams],
  );
  const debouncedResults = React.useMemo(() => {
    return debouce(handleChange, 1000);
  }, [handleChange]);

  const getMatchedProfile = React.useCallback(() => {
    const queryString = new URLSearchParams(params as any).toString();
    getAllListData(
      `${RESUME_ENDPOINT.ResumeValidationMatchingProfile}/${resumeId}?${queryString}`,
      true,
    )
      .then((resp: MatchedProfileResponse) => {
        setLoader(false);
        setGetMoreLoader(false);
        if (params.skip === 0) {
          setMatchedProfile(resp);
        } else {
          setMatchedProfile((pre) => ({
            ...pre,
            data: [...pre.data, ...resp.data],
          }));
        }
        if (
          matchedProfile.data &&
          matchedProfile.data.length < Number(resp.total)
        ) {
          setHasMoreResults(true);
        } else {
          setHasMoreResults(false);
        }
      })
      .catch(() => {
        setLoader(false);
        setGetMoreLoader(false);
        setHasMoreResults(false);
      });
  }, [matchedProfile?.data, params, resumeId]);

  React.useEffect(() => {
    getMatchedProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <Typography component={'div'}>
      <Typography
        component={'div'}
        p={2}
        pt={0}
        pl={0}
        className="d-flex-a flex-sb ">
        <Typography
          component={'div'}
          className="red-color resume-tab-header highlight">
          {'Select Candidate'}
        </Typography>
      </Typography>
      <Typography component={'div'} className="pb-12">
        <Typography component={'span'} className="resume-search-header plr-14">
          Candidate Search
        </Typography>
        <CustomInput
          className="custom-search"
          placeHolder="Search by Name, Phone number, Email"
          onChange={debouncedResults}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end" className="icon-postition">
                <SearchIcon className="icon-size" />
              </InputAdornment>
            ),
          }}
        />
      </Typography>

      <Typography component={'div'} className="list-header plr-14">
        Matched Profiles{' '}
        <span className="red-color count">{matchedProfile?.total}</span>
      </Typography>

      <Typography component={'div'} className="body-hight3" id="matching-id">
        {loader ? (
          <Loader
            pageCenterLoadingHide={true}
            className={'d-flex-ja w-100 h-100'}
          />
        ) : matchedProfile?.data.length ? (
          <InfiniteScroll
            dataLength={matchedProfile.data.length}
            next={() => {
              if (
                matchedProfile.data.length < matchedProfile.total &&
                !getMoreLoader
              ) {
                setGetMoreLoader(true);
                setParams({
                  ...params,
                  skip: params.take + params.skip,
                });
              }
            }}
            hasMore={hasMoreResults}
            loader={
              getMoreLoader && (
                <div className="lds-ellipsis">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              )
            }
            scrollableTarget={'matching-id'}>
            {matchedProfile?.data.map((e) => (
              <Typography component={'div'} className="pt-12" key={e.id}>
                <CardDetails
                  setSelectCandidate={setSelectCandidate}
                  info={e}
                  setResumeView={setResumeView}
                />
              </Typography>
            ))}
          </InfiniteScroll>
        ) : (
          <Typography
            component={'div'}
            className={'d-flex-ja w-425 h-100 m-auto'}>
            <Typography
              component={'span'}
              className="f-14 semi-bold black-color">
              <span className="error-matching-profile black-color">
                No matching profiles found. If this is a new candidate, please
                click on the
                <span className="red-color font-bold plr-6 f-12">
                  Create New
                </span>
                button below. <br />
              </span>
              If you believe the candidate already exists on the system, please
              use the
              <span className="red-color font-bold plr-6 f-12">Search box</span>
              above to identify the candidate and update the resume.
            </Typography>
          </Typography>
        )}
      </Typography>

      <Typography component={'div'} className="footer-info">
        {/* <span className="other"> (or) </span> */}
        <Typography
          component={'div'}
          className="resume-btn-footer"
          onClick={() => setSelectCandidate('new')}>
          Create New
        </Typography>
      </Typography>

      <Modal
        open={resumeView.open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className={'resume-modal view-resume'}>
          <Typography id="modal-modal-title" className="d-flex-a flex-sb">
            <Typography
              variant="h6"
              component="h2"
              className="resume-header-name"
              sx={{ transform: 'translateY(-9px)' }}>
              Candidate Resume
            </Typography>
            <div
              className="closebtn"
              onClick={onClose}
              style={{
                cursor: 'pointer',
                transform: 'translateY(-12px)',
                zIndex: 3,
              }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="none"
                viewBox="0 0 18 18">
                <path
                  fill="#000"
                  d="M17.813.189a.645.645 0 00-.91 0l-7.9 7.9-7.905-7.9a.643.643 0 00-.91.91l7.905 7.9-7.901 7.903a.643.643 0 10.91.91l7.9-7.904 7.905 7.9a.643.643 0 00.906-.906L9.912 8.998l7.901-7.903a.643.643 0 000-.906z"
                />
              </svg>
            </div>
          </Typography>
          <Typography id="modal-modal-description">
            <Grid container spacing={2}>
              <Grid item md={12} sm={6}>
                <PdfViewer
                  url={`${process.env.REACT_APP_APIURL}${
                    MASTER_ENDPOINT.Files
                  }/${resumeView.candidateId}?type=resume&userId=${String(
                    userInfo?.id,
                  )}`}
                  hideDownload={false}
                  scale={1.4}
                />
              </Grid>
            </Grid>
          </Typography>
        </Box>
      </Modal>
    </Typography>
  );
};
