import { Typography } from '@mui/material';
import FlagdropdownIcon from 'components/SvgAsCode/FlagdropdownIcon';
import ResumevaliodationFlagSvg from 'components/SvgAsCode/ResumevalidationFlag';
import React, { useEffect } from 'react';

type Props = {
  tab: string;
  setAnalytics: (src: boolean) => void;
  setShownAddNotes?: (src: boolean) => void;
  shownNotes?: boolean;
  hideAddNotes?: boolean;
  FlagDropdown?: boolean;
};
export const HeaderTab: React.FC<Props> = (props: Props) => {
  const {
    tab,
    setAnalytics,
    setShownAddNotes,
    shownNotes,
    hideAddNotes,
    FlagDropdown,
  } = props;
  const [open, setopen] = React.useState<boolean>(false);
  function handleOpen() {
    setopen((p) => !p);
  }
  const [selected, setselected] = React.useState<any>(1);
  const FlagObj = {
    1: '#FF0000',
    2: '#00BA00',
    3: '#FCEC11',
  };
  const handleFormLogic = (id: number) => {
    setselected(id);
  };
  useEffect(() => {
    setselected(1);
  }, []);

  return (
    <Typography component={'div'}>
      <Typography component={'div'} className="d-flex-a flex-sb plr-14 pb-4">
        <Typography component={'div'} className="d-flex-a g-10">
          <Typography
            component={'div'}
            className={`resume-tab-header  ${
              tab === 'notes' ? 'red-color  highlight' : ''
            }`}
            onClick={() => setAnalytics(false)}>
            Notes &nbsp; (06)
          </Typography>

          <Typography
            component={'div'}
            className={`resume-tab-header  ${
              tab !== 'notes' ? 'red-color  highlight' : ''
            }`}
            onClick={() => setAnalytics(true)}>
            Analytical Details
          </Typography>
        </Typography>
        {hideAddNotes
          ? null
          : !shownNotes && (
              <Typography
                component={'div'}
                className="btn-update-resume add-notes"
                onClick={() => setShownAddNotes(true)}>
                <span className="plus">+</span> Add Notes
              </Typography>
            )}
        {FlagDropdown ? (
          <Typography component={'div'} className="flag-dropdown">
            <span className="flagtxt">Resume Status</span>
            <span className="flagdropdwn">
              <span className="icon" onClick={handleOpen}>
                <FlagdropdownIcon open={open} color={FlagObj[selected]} />
              </span>
              {open && (
                <div
                  style={{
                    backgroundColor: 'transparent',
                    bottom: '0px',
                    left: '0px',
                    position: 'fixed',
                    right: '0px',
                    top: '0px',
                  }}
                  onClick={handleOpen}
                />
              )}
              {open && (
                <span className="dropdown">
                  {Object.keys(FlagObj).map((col: any) => {
                    if (Number(col) === Number(selected)) {
                      // eslint-disable-next-line react/jsx-no-useless-fragment
                      return <></>;
                    }
                    return (
                      <div
                        className="Flags"
                        key={col}
                        onClick={() => handleFormLogic(col)}>
                        <span className={'roundcheck'} />
                        <span className="flagicon">
                          <ResumevaliodationFlagSvg
                            Color={
                              FlagObj && FlagObj[col] ? FlagObj[col] : undefined
                            }
                          />
                        </span>
                      </div>
                    );
                  })}
                </span>
              )}
            </span>
          </Typography>
        ) : null}
      </Typography>
    </Typography>
  );
};
