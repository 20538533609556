import { Button, FormHelperText, Typography } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import React from 'react';

type Props = {
  closeNotes?: () => void;
  setValue?: (formVal: string, value: string) => void;
  currentValue?: string;
  hideLable?: boolean;
  hideButton?: boolean;
};
export const AddNotes: React.FC<Props> = (props: Props) => {
  const {
    closeNotes,
    setValue,
    currentValue = '',
    hideLable = false,
    hideButton = false,
  } = props;
  const [notes, setNotes] = React.useState(currentValue ? currentValue : '');
  const [errorShown, setErrorShown] = React.useState(false);

  const close = () => {
    closeNotes && closeNotes();
  };
  const saveNotes = () => {
    setErrorShown(true);
    if (notes || notes.trim()) {
      setValue && setValue('notes', notes);
      close();
    }
  };

  return (
    <Typography component={'div'} className="mt-4 mb-7">
      {hideLable ? null : (
        <Typography
          component={'label'}
          className="title label-figma pl-17 pb-2">
          Type your notes here
        </Typography>
      )}
      <Typography component={'div'} className="mb-5 mt-4">
        <CustomInput
          value={notes}
          isTextArea={true}
          className="custom-textarea"
          minRows={6}
          onChange={(e) => {
            setNotes(e.target.value);
            if (hideButton && (e.target.value || e.target.value.trim())) {
              setValue && setValue('notes', notes);
            }
          }}
        />
      </Typography>
      {errorShown && (!notes || !notes.trim()) && (
        <FormHelperText className="errorMsg pl-30">
          {'Notes is required'}
        </FormHelperText>
      )}
      {hideButton ? null : (
        <Typography component={'div'} className="d-flex-jae pt-4 ">
          <Button
            className="rec-add-btn cancel-btn-notes mr-10"
            onClick={() => close()}>
            Cancel
          </Button>
          <Button
            className="rec-add-btn ad-btn-notes mr-10"
            onClick={() => saveNotes()}>
            Save
          </Button>
        </Typography>
      )}
    </Typography>
  );
};
