import { Grid, Typography } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomDatePicker from 'components/DatePicker';
import React from 'react';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form';
import { GeneralDetails } from 'types';

type Props = {
  control: UseFormReturn<GeneralDetails>;
};
export const ExperienceInfo: React.FC<Props> = (props: Props) => {
  const { control: formControl } = props;
  const { control, formState } = formControl;

  const {
    fields,
    // append,
    // remove,
    // replace,
  } = useFieldArray({
    control,
    name: 'experience',
  });

  // React.useEffect(() => {
  //   append([
  //     {
  //       employer: '',
  //       fromDate: '',
  //       jobTitle: '',
  //       location: '',
  //       project: '',
  //       toDate: '',
  //     },
  //     {
  //       employer: '',
  //       fromDate: '',
  //       jobTitle: '',
  //       location: '',
  //       project: '',
  //       toDate: '',
  //     },
  //     {
  //       employer: '',
  //       fromDate: '',
  //       jobTitle: '',
  //       location: '',
  //       project: '',
  //       toDate: '',
  //     },
  //   ]);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <Typography component={'div'}>
      {fields && fields.length
        ? fields.map((item, index) => (
            <Grid
              container
              spacing={1}
              key={index}
              sx={
                fields.length === index + 1
                  ? {}
                  : {
                      borderBottom: '1px solid #ababff',
                      marginBottom: '10px',
                      paddingBottom: '10px',
                    }
              }>
              <Grid item md={2} sm={12}>
                <Typography component={'label'} className="input-title pb-2">
                  From Date
                </Typography>
                <Typography component={'div'}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`experience.${index}.fromDate`}
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomDatePicker
                          className="custom-datepicker"
                          name={name}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          value={value}
                          error={
                            formState.errors?.experience?.[index]?.fromDate
                              ?.message
                          }
                          {...ref}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={2} sm={12}>
                <Typography component={'label'} className="input-title pb-2">
                  To Date
                </Typography>
                <Typography component={'div'}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`experience.${index}.toDate`}
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomDatePicker
                          className="custom-datepicker"
                          name={name}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          value={value}
                          error={
                            formState.errors?.experience?.[index]?.toDate
                              ?.message
                          }
                          {...ref}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={3} sm={12}>
                <Typography component={'label'} className="input-title pb-2">
                  Job Title
                </Typography>
                <Typography component={'div'}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`experience.${index}.jobTitle`}
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          placeHolder=""
                          value={value}
                          name={name}
                          className="custom-Input"
                          ref={ref}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          error={
                            formState.errors?.experience?.[index]?.jobTitle
                              ?.message
                          }
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={3} sm={12}>
                <Typography component={'label'} className="input-title pb-2">
                  Employer/Client
                </Typography>
                <Typography component={'div'}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`experience.${index}.employerOrClient`}
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          placeHolder=""
                          value={value}
                          name={name}
                          className="custom-Input"
                          ref={ref}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          error={
                            formState.errors?.experience?.[index]
                              ?.employerOrClient?.message
                          }
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={2} sm={12}>
                <Typography component={'label'} className="input-title pb-2">
                  Location
                </Typography>
                <Typography component={'div'}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`experience.${index}.location`}
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          placeHolder=""
                          value={value}
                          name={name}
                          className="custom-Input"
                          ref={ref}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          error={
                            formState.errors?.experience?.[index]?.location
                              ?.message
                          }
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={4} sm={12}>
                <Typography component={'label'} className="input-title pb-2">
                  Project
                </Typography>
                <Typography component={'div'}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`experience.${index}.project`}
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          placeHolder=""
                          value={value}
                          name={name}
                          className="custom-Input"
                          ref={ref}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          error={
                            formState.errors?.experience?.[index]?.project
                              ?.message
                          }
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          ))
        : null}
    </Typography>
  );
};
