import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  ClickAwayListener,
  Drawer,
  Fade,
  IconButton,
  Modal,
  Popover,
  Popper,
  Radio,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
// import DownIcon from 'assets/images/down-arrow-selected.svg';
import FilterIcon from 'assets/images/filter.svg';
import MoreIcon from 'assets/images/MoreIcon.svg';
import CommonUploadBtn from 'components/CommonUploadBtn';
// import UpIcon from 'assets/images/up-arrow-selected.svg';
import CustomInput from 'components/CustomInput/customInput';
import CustomMultiSelect from 'components/CustomMultiSelect/customMultiSelect';
import CustomSelect from 'components/CustomSelect/customSelect';
import CustomSelectSeach from 'components/CustomSelectSearch';
import InfinityScroll from 'components/InfinityScroll';
import ScrollWrapper from 'components/ScrollWrapper';
import SearchBar from 'components/SearchBar/SearchBar';
import userIconAvatar from 'components/SvgAsCode/userIconAvatar';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import debouce from 'lodash.debounce';
import moment from 'moment';
import useStyles from 'pages/private/PrivateStyle';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
// import FileViewer from 'react-file-viewer';
import { Controller, useForm } from 'react-hook-form';
import { Document, Page, pdfjs } from 'react-pdf';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { candidateFilterData } from 'store/reducers/UserReducer';
import { USER_CANDIDATE_FILTER_DATA } from 'store/types/UserTypes';
import { AddNotes, EditCandidate } from 'types';
import { CANDIDATESTATUS } from 'utils/ArrayConstants';
import { alertOptions } from 'utils/Config';
import { MASTER_ENDPOINT, RESUME_ENDPOINT } from 'utils/Constant';
// import Storage from 'utils/Storage';
import { addNoteSchema, editCandidateSchema } from 'utils/ValidatorSchema';

import UploadResumeModal from './UploadResumeModal';

const staticParam = {
  order: 'asc',
  reload: true,
  skip: 0,
  sortby: 'firstName',
  take: 50,
};

const stylenote = {
  bgcolor: 'background.paper',
  boxShadow: '0 2px 10px 0 rgba(0,0,0,0.08)',
  left: '50%',
  minHeight: 200,
  padding: '51px 54px 40px 54px',
  position: 'absolute' as const,
  top: '100px',
  transform: 'translate(-50%, -60px)',
  width: 500,
};

const style = {
  bgcolor: 'background.paper',
  height: '90vh',
  left: '50%',
  overflowY: 'auto',
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
};

const ExampleCustomInput = forwardRef(({ value, onClick }: any) => (
  <button className="calender-candidate-button" onClick={onClick}>
    {value ? value : 'mm/dd/yyyy'}
  </button>
));

export const CandidateList: React.FC<any> = () => {
  const classes = useStyles();
  const [notes, setNotes] = React.useState<any>([]);
  const dispatch: any = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [JobListingData, setJobListingData] = useState<any>([
    { label: 'Select a source for the resume', value: undefined },
  ]);
  const [downloadLoader, setDownloadLoader] = React.useState(false);
  // const [applicationStatus, setApplicationStatus] = React.useState<>('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [skillData, setSkillData] = React.useState<any>([]);
  const [params, setParams] = React.useState<any>({});
  const [loading, setLoading] = React.useState<any>(true);
  const [filterCandidate, setFilterCandidate] = React.useState<string>('');
  const [tableInfo, setTableInfo] = React.useState<any>({
    list: [],
    total: 0,
  });
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageScale, setPageScale] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [workStatusList, setWorkStatusList] = React.useState<any>([]);
  const [opening, setopening] = useState<boolean>(false);
  // const [booleanSearch, setBooleanSearch] = React.useState<string>('');
  function CloseFn() {
    setopening((p) => !p);
    setSuccessUPRESLoader(false);
  }
  const getWorkStatus = () => {
    getAllListData(MASTER_ENDPOINT.WorkStatus + '?status=true').then(
      (resp: any) => {
        setWorkStatusList(resp?.data);
      },
    );
  };

  async function ListApiCall() {
    setResumeuploadLoader(true);
    try {
      const resp = await getAllListData(
        `${RESUME_ENDPOINT.ResumeValidationsources}`,
      );
      console.log(resp, 'resp');
      if (resp && resp.data) {
        setJobListingData(resp.data);
      }
    } catch (error) {
      console.log(error, 'error');
    } finally {
      setResumeuploadLoader(false);
    }
  }

  useEffect(() => {
    ListApiCall();
    getWorkStatus();
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setTotalPages(numPages);
  };

  const handleZoomIn = () => {
    if (pageScale < 3) {
      setPageScale(pageScale + 0.2);
    }
  };

  const handleZoomOut = () => {
    if (pageScale > 0.3) {
      setPageScale(pageScale - 0.2);
    }
  };

  const handleNext = () => {
    if (pageNumber < totalPages) {
      setPageNumber(pageNumber + 1);
    }
  };
  const handlePrevious = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    }
  };

  // const TokenStatus: any = Storage.getTokens();
  // let token = TokenStatus && TokenStatus.token ? TokenStatus.token : '';
  // if (token) {
  //   token = 'Bearer '.concat(token);
  // }
  const [vopen, setVOpen] = React.useState(false);
  const handleViewOpen = () => {
    setVOpen(true);
    setNotes([]);
    getNotes();
  };
  const [SuccessUPRESLoader, setSuccessUPRESLoader] =
    React.useState<any>(false);
  const [ResumeuploadLoader, setResumeuploadLoader] =
    React.useState<any>(false);

  const handleViewClose = () => setVOpen(false);
  const [addOpen, setAddOpen] = React.useState(false);
  const handleAddOpen = () => setAddOpen(true);
  const handleAddClose = () => setAddOpen(false);
  const [editResume, setEditResume] = React.useState(false);
  const handleEditOpen = () => {
    setEditResume(true);
    setValue('city', viewRow?.city ? viewRow?.city : '');
    setValue('state', viewRow?.state ? viewRow?.state : '');
    setValue(
      'workAuthorization',
      viewRow?.workAuthId ? viewRow?.workAuthId : '',
    );
    setValue('pocUserId', viewRow?.pocUserId);
    setValue('pocEmail', viewRow?.pocEmail);
  };
  const handleEditClose = () => setEditResume(false);

  const { control, formState, handleSubmit, reset } = useForm<AddNotes>({
    defaultValues: {
      notes: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(addNoteSchema),
  });

  const {
    control: control1,
    formState: formState1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    setValue,
  } = useForm<EditCandidate>({
    defaultValues: {
      city: '',
      pocEmail: '',
      pocUserId: '',
      state: '',
      workAuthorization: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(editCandidateSchema),
  });

  const getNotes = (id?: string) => {
    const cId = id ? id : viewRow.id;
    getAllListData(
      MASTER_ENDPOINT.Candidates + '/notes?id=&userId=&candidateId=' + cId,
    )
      .then((resp: any) => {
        setTotalPages(0);
        setPageNumber(1);
        setPageScale(1);
        setNotes(resp?.data);
      })
      .catch((err: any) => {});
  };

  const resetForm = () => {
    reset();
    setAddOpen(false);
  };
  const resetFormEdit = () => {
    reset1();
    setEditResume(false);
  };

  const submitForm = (data: any) => {
    const AddNoteobj = {
      candidateId: viewRow.id,
      notes: data.notes,
    };
    addNewData(
      AddNoteobj,
      MASTER_ENDPOINT.Candidates + '/notes',
      {},
      true,
    ).then((resp: any) => {
      getNotes();
      setAddOpen(false);
      resetForm();
    });
  };

  const submitFormEdit = (data: any) => {
    const EditCandidate = {
      city: data.city,
      pocEmail: data.pocEmail ? data.pocEmail : null,
      pocUserId: data.pocUserId ? data.pocUserId : null,
      state: data.state,
      workAuthId: data.workAuthorization,
    };
    updateData(
      viewRow.id,
      EditCandidate,
      MASTER_ENDPOINT.Candidates,
      true,
    ).then((resp: any) => {
      handleCloseResm();
      setEditResume(false);
      resetFormEdit();
      setParams({ ...staticParam, reload: !params.reload });
    });
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>, skill: any) => {
    setSkillData(skill.slice(6));
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };
  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'spring-popper' : undefined;
  const {
    userInfo,
    candidateFilter,
    searchText: headerSearch,
  }: any = useAppSelector((store: any) => store.userLogin);

  useEffect(() => {
    setSearchText(headerSearch ? headerSearch : '');
  }, [headerSearch]);

  const handleSearchChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    event.stopPropagation();
    if (newAlignment) {
      dispatch({
        payload: {
          ...candidateFilter,
          booleanSearch: '',
          skillData: [],
          tabSearch: newAlignment,
          title: '',
        },
        type: USER_CANDIDATE_FILTER_DATA,
      });
      // filterEdit('title', '');
      // filterEdit('skillData', []);
      // setTabSearch(newAlignment);
    }
  };

  useEffect(() => {
    if (Object.keys(params).length) {
      getCandidateData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (Object.keys(params).length) {
      setParams({ ...staticParam, reload: !params.reload });
    } else {
      setParams(staticParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const getCandidateData = async () => {
    setLoading(true);
    setTableInfo({
      list: [],
      total: 0,
    });
    let url = `${MASTER_ENDPOINT.Candidates}?order=${String(
      params.order,
    )}&sortby=${String(params.sortby)}&skip=${String(
      params.skip,
    )}&take=${String(params.take)}&search=${String(
      searchText,
    )}&applicantStatus=${String(filterCandidate)}`;
    if (candidateFilter.applyFilter) {
      let skillId = '';
      if (candidateFilter?.skillData && candidateFilter?.skillData?.length) {
        candidateFilter?.skillData.map((item: any) => {
          skillId += item?.name + ' and ';
        });
      }
      if (skillId) {
        skillId = skillId.replace(/ and \s*$/, '');
      }
      url += `&location=${String(candidateFilter?.location)}&${
        candidateFilter.tabSearch === '1' ? 'jobTitle' : 'jobBoolean'
      }=${String(encodeURIComponent(candidateFilter?.title))}&workAuth=${String(
        candidateFilter?.workAuth.toString(),
      )}&skillId=${
        candidateFilter.tabSearch === '1' && skillId
          ? encodeURIComponent(skillId)
          : ''
      }&organizationId=${String(candidateFilter.organizationId)}`;
      if (candidateFilter?.booleanSearch) {
        url += `&boolean=${String(
          encodeURIComponent(candidateFilter?.booleanSearch),
        )}`;
      }
      url += '&isApply=true';
    }

    await getAllListData(url)
      .then((resp) => {
        setLoading(false);
        setTableInfo({
          list: resp.data,
          total: resp?.total,
        });
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const [anchorElResm, setAnchorElResm] =
    React.useState<HTMLButtonElement | null>(null);
  const openResm = Boolean(anchorElResm);
  const idResm = openResm ? 'simple-popover' : undefined;

  const [viewRow, setViewRow] = React.useState<any>({});
  const handleClick1 = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: any,
  ) => {
    setViewRow(row);
    setAnchorElResm(event.currentTarget);
  };

  const handleCloseResm = () => {
    setViewRow({});
    setAnchorElResm(null);
  };

  const headRow = React.useCallback(() => {
    return (
      <TableHead>
        <TableRow>
          <TableCell className="w-17">
            <Typography component={'div'} className="d-flex-a">
              <Typography className="th-title">Name</Typography>
              {/* <Typography className="sort-box">
                <img
                  src={UpIcon}
                  alt="up"
                  className="down-arrow"
                  onClick={() => {
                    if (params.order !== 'asc') {
                      setParams({ ...staticParam, order: 'asc' });
                    }
                  }}
                />
                <img
                  src={DownIcon}
                  alt="up"
                  className="up-arrow"
                  onClick={() => {
                    if (params.order !== 'desc') {
                      setParams({ ...staticParam, order: 'desc' });
                    }
                  }}
                />
              </Typography> */}
            </Typography>
          </TableCell>
          <TableCell className="w-12">Location</TableCell>
          <TableCell className="w-15">job title</TableCell>
          <TableCell className="w-17">work authorization</TableCell>
          <TableCell className="w-35">Skills</TableCell>
          <TableCell className="w-4" />
        </TableRow>
      </TableHead>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [anchorEl2, setAnchorEl2] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [popUpDate, setPopUpDate] = React.useState('');
  const handleBadgeClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    str: string,
  ) => {
    setPopUpDate(str);
    setAnchorEl2(event.currentTarget);
  };
  const handleBadgeClose = () => {
    setPopUpDate('');
    setAnchorEl2(null);
  };
  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? 'simple-popover2' : undefined;

  const [statusupdateJson, setStatusupdateJson] = React.useState<any>({
    date: '',
    id: '',
    oldDate: '',
    oldStatus: '',
    status: '',
  });

  const [anchorEl3, setAnchorEl3] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const handleStatusClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: any,
  ) => {
    setAnchorEl3(event.currentTarget);
    setStatusupdateJson({
      ...statusupdateJson,
      date: row.applicantStatusDate
        ? moment(row.applicantStatusDate).format('MM/DD/YYYY')
        : '',
      id: row.id,
      oldDate: row.applicantStatusDate
        ? moment(row.applicantStatusDate).format('MM/DD/YYYY')
        : '',
      oldStatus: row.applicantStatus,
      status: row.applicantStatus,
    });
  };
  const handleStatusClose = () => {
    if (
      statusupdateJson.oldStatus !== statusupdateJson.status ||
      (statusupdateJson.oldStatus === CANDIDATESTATUS[2] &&
        statusupdateJson.date !== statusupdateJson.oldDate)
    ) {
      if (statusupdateJson.status === CANDIDATESTATUS[2]) {
        if (!statusupdateJson.date) {
          toast.error('Please select date', alertOptions);
          return true;
        }
      }
      updateData(
        statusupdateJson.id,
        {
          applicantStatus: statusupdateJson.status,
          applicantStatusDate:
            statusupdateJson.status === CANDIDATESTATUS[2]
              ? statusupdateJson.date
              : '',
        },
        MASTER_ENDPOINT.Candidates + '/status',
        true,
      ).then((resp) => {
        setStatusupdateJson({
          date: '',
          id: '',
          oldStatus: '',
          status: '',
        });
        setParams({ ...staticParam, reload: !params.reload });
        setAnchorEl3(null);
      });
    } else {
      setAnchorEl3(null);
    }
  };
  const open3 = Boolean(anchorEl3);
  const id3 = open3 ? 'simple-popover2' : undefined;
  const [users, setUsers] = useState([]);
  const [companyList, setCompanyList] = useState([]);

  const getCompanyList = () => {
    getAllListData(
      MASTER_ENDPOINT.Organization + '?order=asc&sortby=name',
    ).then((resp: any) => {
      setCompanyList(resp?.data);
    });
  };

  const userData = () => {
    getAllListData(
      `${MASTER_ENDPOINT.Admins}?sortby=&order=&roleId=4,5&search=&skip=&take=&organizationId=&status=true`,
    )
      .then((res) => {
        setUsers(res?.data);
      })
      .catch((err) => console.log({ err }));
  };

  const rowProps = React.useCallback((row: any, index: number) => {
    const skillData = row?.candidateSkills
      ? row?.candidateSkills?.slice(0, 6)
      : '';
    let sName = '';
    if (skillData && skillData?.length) {
      skillData.map((item: any) => {
        sName += item ? item?.skillName + ', ' : '';
      });
    }
    if (sName) {
      sName = sName.replace(/,\s*$/, '');
    }
    return (
      <TableRow
        key={index}
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
        }}>
        <TableCell>
          <Typography component={'div'} className="d-flex-a">
            <Typography
              className="td-text pointer"
              onClick={() => {
                setViewRow(row);
                setNotes([]);
                setVOpen(true);
                getNotes(row.id);
                // setTimeout(() => {
                //   handleViewOpen();
                // }, 1000);
              }}>
              {row?.firstName} {row?.lastName}
            </Typography>
            {['W2 Available', 'W2 OnAssignment', 'W2 Prospective'].includes(
              row.applicantStatus,
            ) && row.applicantStatus !== 'None' ? (
              <Typography
                component={'button'}
                aria-describedby={id2}
                onClick={(e) => {
                  if (row.applicantStatus === 'W2 OnAssignment') {
                    handleBadgeClick(e, row.applicantStatusDate);
                  }
                }}
                className={
                  row.applicantStatus === 'W2 Available'
                    ? 'w2a-badge d-flex-ja'
                    : row.applicantStatus === 'W2 Prospective'
                    ? 'w2p-badge d-flex-ja'
                    : 'w20a-badge d-flex-ja'
                }>
                {row.applicantStatus === 'W2 Available'
                  ? 'W2A'
                  : row.applicantStatus === 'W2 Prospective'
                  ? 'W2P'
                  : row.applicantStatus === 'W2 OnAssignment'
                  ? 'W2oA'
                  : ''}
              </Typography>
            ) : (
              <Typography />
            )}
            {[4, 5].includes(userInfo.roleId) ? (
              <Button
                aria-describedby={id3}
                onClick={(e) => handleStatusClick(e, row)}
                sx={{
                  minWidth: 'auto !important',
                  padding: '0px !important',
                }}>
                <ArrowDropDownIcon sx={{ fill: '#D8D8D8' }} />
              </Button>
            ) : (
              <Typography />
            )}
          </Typography>
        </TableCell>
        <TableCell>
          {`${row?.city ? String(row?.city) : ''} ${
            row?.state && row?.city ? ',' : ''
          } ${row?.state ? `${String(row?.state)}` : ''}`}
          {/* {row?.city && row?.state
            ? row?.city + ', ' + row?.state
            : row?.city + row?.state} */}
        </TableCell>
        <TableCell>{row?.jobTitle}</TableCell>
        <TableCell>{row?.workStatusName ? row?.workStatusName : '-'}</TableCell>
        <TableCell>
          {sName}{' '}
          {skillData?.length ? (
            skillData?.length !== row?.candidateSkills?.length ? (
              <button
                className="more-buttom"
                aria-describedby={id}
                type="button"
                onClick={(e) => handleClick(e, row.candidateSkills)}>
                More...
              </button>
            ) : (
              ''
            )
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell>
          <Button
            onClick={(e) => handleClick1(e, row)}
            sx={{
              minWidth: 'auto !important',
              padding: '0 10px !important',
            }}>
            <img src={MoreIcon} alt="More" />
          </Button>
        </TableCell>
      </TableRow>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableData = React.useMemo(() => {
    return (
      <InfinityScroll
        paramState={setParams}
        scrollableTargetId={'Skills'}
        data={tableInfo.list}
        rowPorops={rowProps}
        headRow={headRow}
        count={tableInfo.total}
        columnCount={6}
        loading={loading}
        params={params}
      />
    );
  }, [params, tableInfo.list, tableInfo.total, rowProps, headRow, loading]);

  const handleChange = (e) => {
    setSearchText(e.target.value);
    // dispatch({
    //   payload: e.target.value,
    //   type: USER_SEARCH,
    // });
  };

  const debouncedResults = useMemo(() => {
    return debouce(handleChange, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    userData();
    getCompanyList();
    return () => {
      debouncedResults.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const statusFilterChange = (str: string) => {
    if (str !== filterCandidate) {
      setFilterCandidate(str);
      setParams({ ...staticParam, reload: !params.reload });
    }
  };

  const [openfilter, setOpenFilter] = React.useState(false);

  const applyFilter = () => {
    // if (candidateFilter.tabSearch === '2') {
    //   if (candidateFilter?.booleanSearch) {
    //     const searchAnd = candidateFilter?.booleanSearch
    //       .toLocaleLowerCase()
    //       .search(' and ');
    //     const searchOr = candidateFilter?.booleanSearch
    //       .toLocaleLowerCase()
    //       .search(' or ');
    //     if (searchAnd === -1 && searchOr === -1) {
    //       toast.error('Enter a search with boolean condition');
    //       setLoading(false);
    //       return true;
    //     }
    //   }
    //   if (candidateFilter?.title) {
    //     const searchAndTitle = candidateFilter?.title
    //       .toLocaleLowerCase()
    //       .search(' and ');
    //     const searchOrTitle = candidateFilter?.title
    //       .toLocaleLowerCase()
    //       .search(' or ');
    //     if (searchAndTitle === -1 && searchOrTitle === -1) {
    //       toast.error('Enter a search with boolean condition');
    //       setLoading(false);
    //       return true;
    //     }
    //   }
    // }
    if (!_.isEqual(candidateFilterData, candidateFilter)) {
      filterEdit('applyFilter', true);
      setParams({ ...staticParam, reload: !params.reload });
    }
    setOpenFilter(false);
  };

  const filterEdit = (key: string, val: any) => {
    dispatch({
      payload: {
        ...candidateFilter,
        [key]: val,
      },
      type: USER_CANDIDATE_FILTER_DATA,
    });
  };

  const resetFilter = () => {
    if (!_.isEqual(candidateFilterData, candidateFilter)) {
      setParams({ ...staticParam, reload: !params.reload });
      dispatch({
        payload: { ...candidateFilterData },
        type: USER_CANDIDATE_FILTER_DATA,
      });
    }
    // setTabSearch('1');
    setOpenFilter(false);
  };

  const candidateResumeDownload = async (row: any) => {
    setDownloadLoader(true);
    const file = await new Promise((resolve) => {
      const extension = row.awsResumePath
        ? row.awsResumePath.split('.')
          ? row.awsResumePath.split('.')?.[1]
            ? row.awsResumePath.split('.')?.[1]
            : '1'
          : '2'
        : '3';
      const fullName = row?.consultantName
        ? row?.consultantName
        : `${String(row?.firstName)} ${
            row?.lastName ? String(row?.lastName) : ''
          }`;
      saveAs(
        `${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.Files}/${String(
          row?.id,
        )}?type=resume&isDownload=true&userId=${String(userInfo?.id)}`,
        `${String(fullName)}.${String(extension)}`,
        { autoBom: true },
      );
      resolve(true);
    });
    if (file !== 'error') {
      setDownloadLoader(false);
      return;
    }
  };
  async function ResumeSubmit(sourceId: any, Files: any) {
    const formData = new FormData();
    formData.append('sourceId', sourceId);
    formData.append('file', Files && Files[0]);
    formData.append('jobPostingId', '');
    formData.append('type', 'candidateResume');
    // console.log(formData, 'formData');
    setResumeuploadLoader(true);
    setSuccessUPRESLoader(false);

    try {
      const resp = await addNewData(
        formData,
        RESUME_ENDPOINT.ResumeValidation,
        {},
        false,
      );
      // console.log(resp, 'resp');
      if (resp) {
        setSuccessUPRESLoader(true);
        setResumeuploadLoader(false);
      }
    } catch (error) {
      setSuccessUPRESLoader(false);
      toast.error(error, alertOptions);
    } finally {
      setResumeuploadLoader(false);
      // console.log(SuccessUPRESLoader, 'successloader');
    }
  }
  return (
    <Typography component={'div'}>
      {userInfo.roleId !== 1 ? (
        <Grid container sx={{ padding: '0px 10px 0px 20px' }}>
          <Grid item md={8}>
            <Typography component={'div'} className="d-flex-a">
              <SearchBar onChange={debouncedResults} />
              <Typography component={'div'}>
                <Typography className="d-flex-a">
                  <Button
                    className={
                      filterCandidate === ''
                        ? 'header-tab-btn-active'
                        : 'header-tab-btn-normal'
                    }
                    onClick={() => {
                      statusFilterChange('');
                    }}>
                    All
                  </Button>
                  <Button
                    className={
                      filterCandidate === 'W2 Available'
                        ? 'header-tab-btn-active'
                        : 'header-tab-btn-normal'
                    }
                    onClick={() => {
                      statusFilterChange('W2 Available');
                    }}>
                    W2 Available
                  </Button>
                  <Button
                    className={
                      filterCandidate === 'W2 OnAssignment'
                        ? 'header-tab-btn-active'
                        : 'header-tab-btn-normal'
                    }
                    onClick={() => {
                      statusFilterChange('W2 OnAssignment');
                    }}>
                    On Assignment
                  </Button>
                  <Button
                    className={
                      filterCandidate === 'W2 Prospective'
                        ? 'header-tab-btn-active'
                        : 'header-tab-btn-normal'
                    }
                    onClick={() => {
                      statusFilterChange('W2 Prospective');
                    }}>
                    Prospective
                  </Button>
                </Typography>
              </Typography>
            </Typography>
          </Grid>
          <Grid item md={4} className="d-flex-jae resume-ipld-gap">
            <Typography component={'div'} className="d-flex-a">
              <CommonUploadBtn
                onPress={CloseFn}
                name={'Add New Candidate'}
                Icon={userIconAvatar}
                // width="130px"
                width="148px"
                svgStyle={{
                  height: '15px',
                  width: '13px',
                }}
              />

              <UploadResumeModal
                open={opening}
                ClickFn={CloseFn}
                SubmitFn={ResumeSubmit}
                Success={SuccessUPRESLoader}
                Loader={ResumeuploadLoader}
                JobListingData={JobListingData}
              />
            </Typography>
            <Typography component={'div'} className="d-flex-a">
              <Button
                className="header-filter-box"
                onClick={() => setOpenFilter(true)}>
                <img src={FilterIcon} alt="Filter" />
              </Button>
              <Drawer
                className="filter-drawer"
                open={openfilter}
                anchor={'right'}
                onClose={() => setOpenFilter(false)}>
                <Typography className="dra-title ">Filter</Typography>
                <form>
                  <Grid container>
                    <Grid item md={12} sm={12}>
                      <Typography className="row-pad">
                        <label className="dra-f-title">Company</label>
                        <CustomSelect
                          onChange={(e: any) => {
                            filterEdit('organizationId', e?.target?.value);
                          }}
                          value={candidateFilter.organizationId}
                          labelKey={'name'}
                          valueKey={'id'}
                          options={
                            companyList && companyList.length > 0
                              ? companyList
                                  .filter((cmpny) => cmpny.name === 'CoxLittle')
                                  ?.map((cmp) => ({
                                    id: cmp.id,
                                    name: cmp.name,
                                  }))
                              : []
                          }
                          placeHolder={''}
                          className={'common-select'}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={12} sm={12}>
                      <Typography className="row-pad">
                        <Accordion expanded>
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon style={{ display: 'none' }} />
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <label className="dra-f-title">Skills</label>
                            <Typography
                              component={'div'}
                              sx={{
                                display: 'flex',
                                position: 'absolute',
                                // right: '30px',
                                right: '0px',
                              }}>
                              <ToggleButtonGroup
                                // color="primary"
                                value={candidateFilter.tabSearch}
                                exclusive
                                onChange={handleSearchChange}
                                aria-label="Platform">
                                <ToggleButton
                                  value="1"
                                  sx={{
                                    background:
                                      candidateFilter.tabSearch === '1'
                                        ? '#636366 !important'
                                        : 'rgba(118,118,128,0.24)',
                                    border: '0px',
                                    color: 'white !important',
                                    fontSize: '13px',
                                    fontWeight: 'bold',
                                    height: '30px',
                                    justifyContent: 'center',
                                    paddingTop: '13px',
                                    textTransform: 'capitalize',
                                  }}>
                                  Quick Search
                                </ToggleButton>
                                <ToggleButton
                                  value="2"
                                  sx={{
                                    background:
                                      candidateFilter.tabSearch === '2'
                                        ? '#636366 !important'
                                        : 'rgba(118,118,128,0.24)',
                                    border: '0px',
                                    color: 'white !important',
                                    fontSize: '13px',
                                    fontWeight: 'bold',
                                    height: '30px',
                                    justifyContent: 'center',
                                    paddingTop: '13px',
                                    textTransform: 'capitalize',
                                    width: '85px',
                                  }}>
                                  Boolean
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {candidateFilter.tabSearch === '1' ? (
                              <>
                                <CustomSelectSeach
                                  value={{}}
                                  onChange={(e) => {
                                    if (e) {
                                      const fIdx =
                                        candidateFilter?.skillData.findIndex(
                                          (item) => item.id === e.id,
                                        );
                                      if (fIdx === undefined) {
                                        filterEdit('skillData', [e]);
                                      }
                                      if (fIdx === -1) {
                                        filterEdit('skillData', [
                                          ...candidateFilter.skillData,
                                          e,
                                        ]);
                                      }
                                    }
                                  }}
                                  type={'multiple'}
                                  className={'skill-job-select'}
                                />
                                <Typography
                                  component={'div'}
                                  className="d-flex-a"
                                  sx={{ flexWrap: 'wrap' }}>
                                  {candidateFilter.skillData &&
                                  candidateFilter.skillData.length ? (
                                    candidateFilter.skillData.map(
                                      (item: any, index: number) => {
                                        return (
                                          <Typography
                                            className="search-item"
                                            key={index}>
                                            {item?.name}
                                            <Typography
                                              onClick={() => {
                                                filterEdit(
                                                  'skillData',
                                                  candidateFilter.skillData.filter(
                                                    (row: any) =>
                                                      row.id !== item?.id,
                                                  ),
                                                );
                                              }}>
                                              <CloseIcon />
                                            </Typography>
                                          </Typography>
                                        );
                                      },
                                    )
                                  ) : (
                                    <Typography />
                                  )}
                                </Typography>
                              </>
                            ) : (
                              <CustomInput
                                isTextArea={true}
                                className={classes.customTextarea}
                                placeHolder="input..."
                                value={candidateFilter?.booleanSearch}
                                onChange={(e: any) => {
                                  filterEdit('booleanSearch', e?.target?.value);
                                }}
                                minRows={5}
                              />
                            )}
                          </AccordionDetails>
                        </Accordion>
                      </Typography>
                    </Grid>
                    <Grid item md={12} sm={12}>
                      <Typography className="row-pad">
                        <label className="dra-f-title">Title</label>
                        {candidateFilter.tabSearch !== '1' ? (
                          <CustomInput
                            isTextArea={true}
                            className={classes.customTextarea}
                            placeHolder="input..."
                            value={candidateFilter?.title}
                            onChange={(e: any) => {
                              filterEdit('title', e?.target?.value);
                            }}
                            minRows={5}
                          />
                        ) : (
                          <CustomInput
                            placeHolder=""
                            // variant="outlined"
                            onChange={(e) => {
                              filterEdit('title', e?.target?.value);
                            }}
                            value={candidateFilter?.title}
                            className={classes.custominput}
                          />
                        )}
                      </Typography>
                    </Grid>
                    <Grid item md={12} sm={12}>
                      <Typography className="row-pad">
                        <label className="dra-f-title">Location</label>
                        <CustomInput
                          placeHolder=""
                          // variant="outlined"
                          onChange={(e) => {
                            filterEdit('location', e?.target?.value);
                          }}
                          value={candidateFilter.location}
                          className={classes.custominput}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={12} sm={12}>
                      <Typography className="row-pad">
                        <label className="dra-f-title">Work Auth</label>
                        {/* <CustomSelect
                          onChange={(e: any) => {
                            filterEdit('workAuth', e?.target?.value);
                          }}
                          value={candidateFilter.workAuth}
                          labelKey={'name'}
                          valueKey={'id'}
                          options={workStatusList}
                          placeHolder={''}
                          className={'common-select'}
                        /> */}
                        <CustomMultiSelect
                          onChange={(e: any) => {
                            filterEdit('workAuth', e?.target?.value);
                          }}
                          value={candidateFilter?.workAuth}
                          labelKey={'name'}
                          valueKey={'id'}
                          placeHolder={''}
                          className={'common-select'}
                          options={workStatusList}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={12} sm={12}>
                      <Typography
                        className="d-flex-a flex-sb"
                        sx={{ padding: '15px 12px' }}>
                        <Button
                          className="m-blue-btn mr-8"
                          onClick={resetFilter}>
                          Reset
                        </Button>
                        <Button className="m-blue-btn" onClick={applyFilter}>
                          Apply
                        </Button>
                      </Typography>
                    </Grid>
                  </Grid>
                </form>{' '}
              </Drawer>
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Typography />
      )}
      <Grid container>
        <Grid item md={12}>
          <TableContainer component={Paper} className="candidate-table">
            {tableData}
          </TableContainer>
        </Grid>
      </Grid>
      <Popover
        className="shadow-popover-sa"
        id={id3}
        open={open3}
        anchorEl={anchorEl3}
        onClose={handleStatusClose}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}>
        <Typography component={'div'} className="inn-text">
          <Typography className="arrow-top-left" />
          {CANDIDATESTATUS.map((item: string, index: number) => {
            return (
              <Typography
                component={'div'}
                className={'radio-text d-flex-a'}
                key={index}>
                <Radio
                  onChange={(e) => {
                    setStatusupdateJson({
                      ...statusupdateJson,
                      status: e.target.value,
                    });
                  }}
                  checked={statusupdateJson.status === item}
                  value={item}
                  size="small"
                  color="success"
                  name="radio-buttons"
                  sx={{
                    '& .Mui-checked > .MuiSvgIcon-root': {
                      color: 'green !important',
                    },
                    '& .MuiSvgIcon-root': {
                      color: 'grey',
                    },
                    padding: '0px',
                  }}
                />
                <Typography className="options">{item}</Typography>
                {statusupdateJson.status === CANDIDATESTATUS[2] &&
                  index === 2 && (
                    <DatePicker
                      selected={
                        statusupdateJson.date
                          ? new Date(statusupdateJson.date)
                          : null
                      }
                      minDate={new Date()}
                      onChange={(date) => {
                        setStatusupdateJson({
                          ...statusupdateJson,
                          date: moment(date).format('MM/DD/YYYY'),
                        });
                      }}
                      customInput={<ExampleCustomInput />}
                    />
                  )}
              </Typography>
            );
          })}
          {/* <Typography component={'div'} className="bottomText">
            Point of Contact: John Miller
          </Typography> */}
        </Typography>
      </Popover>
      <Popover
        className="shadow-popover-wa"
        id={id2}
        open={open2}
        anchorEl={anchorEl2}
        onClose={handleBadgeClose}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}>
        <Typography component={'div'} className="inn-text">
          <Typography className="arrow-top-left" />
          <Typography>
            Estimated Project End: {moment(popUpDate).format('MM/DD/YYYY')}
            {/* Point of Contact: John Miller */}
          </Typography>
        </Typography>
      </Popover>
      {open && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Popper id={id} open={open} anchorEl={anchorEl} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps}>
                <Box className={'show-more-box'}>
                  {skillData && skillData.length
                    ? skillData.map((row: any, index: number) => {
                        return (
                          row?.skillName +
                          (index + 1 === skillData.length ? '' : ', ')
                        );
                      })
                    : ''}
                </Box>
              </Fade>
            )}
          </Popper>
        </ClickAwayListener>
      )}
      <Modal
        className="view-resume"
        open={vopen}
        // onClose={handleViewClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <IconButton
            aria-label="Close"
            onClick={handleViewClose}
            sx={{
              position: 'absolute',
              right: 5,
              top: 5,
            }}>
            <CloseIcon />
          </IconButton>
          <Grid container sx={{ height: '100%' }}>
            <Grid item md={7.6} sm={12} xs={12}>
              <Typography
                component={'div'}
                sx={{ padding: '50px 50px 10px 50px' }}>
                <Typography
                  className="add-m-title d-flex-a flex-sb"
                  sx={{ marginBottom: '12px' }}>
                  Candidate Resume
                  {downloadLoader ? (
                    <Typography component={'div'}>
                      <CircularProgress sx={{ color: '#340000' }} size={24} />
                    </Typography>
                  ) : viewRow.awsResumePath ? (
                    <Typography
                      className="pointer"
                      component={'div'}
                      sx={{
                        color: '#0A84FF',
                        fontFamily: 'Red Hat Display Bold !important',
                        fontSize: '15px !important',
                        textDecoration: 'underline',
                      }}
                      onClick={() => {
                        candidateResumeDownload(viewRow);
                      }}>
                      Download
                    </Typography>
                  ) : (
                    ''
                  )}
                </Typography>
                <Typography sx={{ marginBottom: '12px' }}>
                  Resume Info
                </Typography>
                {viewRow.awsResumePath && (
                  <Typography>
                    <div className="pdf-app-views">
                      <div className="pdf-footer">
                        <div className="pdf-button-container">
                          <IconButton
                            aria-label="Zoom In"
                            onClick={handleZoomIn}
                            disabled={pageScale >= 3}
                            color="primary">
                            <ZoomInIcon />
                          </IconButton>
                          <IconButton
                            aria-label="Zoom Out"
                            onClick={handleZoomOut}
                            disabled={pageScale <= 0.3}
                            color="primary">
                            <ZoomOutIcon />
                          </IconButton>
                        </div>

                        <div className="pdf-page-text">
                          Page {pageNumber} of {totalPages}
                        </div>

                        <div className="pdf-button-container">
                          <IconButton
                            aria-label="Previous"
                            onClick={handlePrevious}
                            disabled={pageNumber === 1}
                            color="primary">
                            <ArrowBackIosIcon />
                          </IconButton>
                          <IconButton
                            aria-label="Next"
                            onClick={handleNext}
                            disabled={pageNumber === totalPages}
                            color="primary">
                            <ArrowForwardIosIcon />
                          </IconButton>
                        </div>
                      </div>
                      <div className="pdf-page-container">
                        <Document
                          file={`${process.env.REACT_APP_APIURL}${
                            MASTER_ENDPOINT.Files
                          }/${String(viewRow.id)}?type=resume&userId=${String(
                            userInfo?.id,
                          )}`}
                          onLoadSuccess={onDocumentLoadSuccess}>
                          <Page pageNumber={pageNumber} scale={pageScale} />
                        </Document>
                      </div>
                    </div>

                    {/* <DocViewer
                      style={{
                        height: '75vh',
                        overflowY: 'scroll',
                      }}
                      prefetchMethod="GET"
                      requestHeaders={{
                        Authorization: token,
                      }}
                      documents={[
                        {
                          fileType: 'application/pdf',
                          uri: `${process.env.REACT_APP_APIURL}${
                            MASTER_ENDPOINT.Files
                          }/${String(viewRow.id)}?type=resume`,
                        },
                      ]}
                      config={{
                        csvDelimiter: ',',
                        header: {
                          disableFileName: true,
                          disableHeader: true,
                          retainURLParams: false,
                        },
                        pdfVerticalScrollByDefault: false,
                        pdfZoom: {
                          defaultZoom: 1, // 1 as default,
                          zoomJump: 0.1, // 0.1 as default,
                        },
                      }}
                      pluginRenderers={DocViewerRenderers}
                    /> */}

                    {/* <FileViewer
                        filePath={`${process.env.REACT_APP_APIURL}${
                          MASTER_ENDPOINT.Files
                        }/${String(viewRow.id)}?type=resume`}
                        fileType={
                          viewRow.awsResumePath
                            ? viewRow.awsResumePath.split('.')
                              ? viewRow.awsResumePath.split('.')?.[1]
                                ? viewRow.awsResumePath.split('.')?.[1]
                                : '1'
                              : '2'
                            : '3'
                        }
                      /> */}
                  </Typography>
                )}
              </Typography>
            </Grid>
            <Grid
              item
              md={4.4}
              sm={12}
              xs={12}
              sx={{ backgroundColor: '#efefef' }}>
              <Typography
                component={'div'}
                sx={{ marginTop: '12px', padding: '33px 35px 10px 35px' }}>
                <Typography
                  component={'div'}
                  className="d-flex-a flex-sb"
                  sx={{ marginBottom: '12px' }}>
                  <Typography className="add-m-title">Notes</Typography>
                  {userInfo.roleId !== 1 && (
                    <Button
                      className="m-blue-btn "
                      onClick={() => {
                        handleAddOpen();
                      }}>
                      Add Notes
                    </Button>
                  )}
                </Typography>
                <Typography component={'div'} className="notes-box">
                  {notes.length > 0 &&
                    notes.map((data, index) => (
                      <Typography
                        key={index}
                        component={'div'}
                        className="notes-item"
                        style={{ minHeight: 'unset', wordBreak: 'break-all' }}>
                        <Typography>{data.notes}</Typography>
                        <Typography className={'spanNotes'}>
                          {data.user.firstName} {data.user.lastName}{' '}
                          {moment(data.createdAt).format('MM/DD/YYYY')}
                        </Typography>
                      </Typography>
                    ))}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Popover
        className="shadow-popover"
        id={idResm}
        open={openResm}
        anchorEl={anchorElResm}
        onClose={handleCloseResm}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}>
        <Typography component={'div'} className="inner-pop-text">
          <Typography className="arrow-top" />
          <Typography onClick={handleViewOpen}>View Resume</Typography>
          {[4, 5].includes(userInfo.roleId) && (
            <Typography onClick={handleEditOpen}>Edit Candidate</Typography>
          )}
        </Typography>
      </Popover>
      <Modal
        open={addOpen}
        // onClose={handleAddClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={stylenote}>
          <IconButton
            aria-label="Close"
            onClick={handleAddClose}
            sx={{
              position: 'absolute',
              right: 5,
              top: 5,
            }}>
            <CloseIcon />
          </IconButton>
          <form onSubmit={handleSubmit(submitForm)}>
            <Typography className="recrutor-title">Add Notes</Typography>
            <Grid container>
              <Grid item md={12} sm={12}>
                <Typography>
                  <label>Notes</label>
                  <span className="errorText">{'*'}</span>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="notes"
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          isTextArea={true}
                          className={classes.customTextarea}
                          placeHolder=""
                          value={value}
                          ref={ref}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          error={formState.errors?.notes?.message}
                          minRows={5}
                        />
                      );
                    }}
                  />
                </Typography>
                <Typography
                  component={'div'}
                  className="d-flex-a flex-sb"
                  sx={{ marginTop: '30px !important' }}>
                  <Button className="cancel-btn" onClick={resetForm}>
                    Cancel
                  </Button>
                  <Button className="save-btn" type={'submit'}>
                    Save
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
      <Modal
        open={editResume}
        // onClose={handleEditClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={stylenote}>
          <ScrollWrapper>
            <IconButton
              aria-label="Close"
              onClick={handleEditClose}
              sx={{
                position: 'absolute',
                right: 5,
                top: 5,
              }}>
              <CloseIcon />
            </IconButton>
            <form onSubmit={handleSubmit1(submitFormEdit)}>
              <Typography className="recrutor-title">
                Edit Candidate Details
              </Typography>
              <Grid container>
                <Grid item md={12} sm={12}>
                  <Typography className="candidate-name">
                    {viewRow?.firstName + ' ' + viewRow?.lastName}
                  </Typography>
                  <Typography>
                    <label>City</label>
                    <span className="errorText">{'*'}</span>
                    <Controller
                      control={control1}
                      defaultValue=""
                      name="city"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            className={classes.custominput}
                            placeHolder=""
                            value={value}
                            ref={ref}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            error={formState1.errors?.city?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                  <Typography>
                    <label>State</label>
                    <span className="errorText">{'*'}</span>
                    <Controller
                      control={control1}
                      defaultValue=""
                      name="state"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            className={classes.custominput}
                            placeHolder=""
                            value={value}
                            ref={ref}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            error={formState1.errors?.state?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                  <Typography>
                    <label>Work Authorization</label>
                    <span className="errorText">{'*'}</span>
                    <Controller
                      control={control1}
                      defaultValue=""
                      name="workAuthorization"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomSelect
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            value={value}
                            labelKey={'name'}
                            valueKey={'id'}
                            options={workStatusList}
                            placeHolder={''}
                            className={'common-select'}
                            error={
                              formState1.errors?.workAuthorization?.message
                            }
                          />
                        );
                      }}
                    />
                  </Typography>
                  <Typography>
                    <label>POC Name</label>
                    <Controller
                      control={control1}
                      defaultValue=""
                      name="pocUserId"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomSelect
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            value={value}
                            searchable={true}
                            labelKey={'firstName'}
                            label1Key={'lastName'}
                            valueKey={'id'}
                            options={users}
                            placeHolder={''}
                            className={'common-select'}
                          />
                        );
                      }}
                    />
                  </Typography>
                  <Typography>
                    <label>POC Email</label>
                    <Controller
                      control={control1}
                      defaultValue=""
                      name="pocEmail"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            className={classes.custominput}
                            placeHolder=""
                            value={value}
                            ref={ref}
                            error={formState1.errors?.pocEmail?.message}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                          />
                        );
                      }}
                    />
                  </Typography>

                  <Typography
                    component={'div'}
                    className="d-flex-a flex-sb"
                    sx={{ marginTop: '30px !important' }}>
                    <Button className="cancel-btn" onClick={resetFormEdit}>
                      Cancel
                    </Button>
                    <Button className="save-btn" type={'submit'}>
                      Save
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </ScrollWrapper>
        </Box>
      </Modal>
    </Typography>
  );
};
