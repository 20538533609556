import { Grid, Typography } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomDatePicker from 'components/DatePicker';
import React from 'react';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form';
import { GeneralDetails } from 'types';

type Props = {
  control: UseFormReturn<GeneralDetails>;
};

export const EducationInfo: React.FC<Props> = (props: Props) => {
  const { control: formControl } = props;
  const { control, formState } = formControl;

  const { fields } = useFieldArray({
    control,
    name: 'educations',
  });

  // React.useEffect(() => {
  //   append([
  //     {
  //       country: '',
  //       degree: '',
  //       graduationDate: '',
  //       university: '',
  //     },
  //     {
  //       country: '',
  //       degree: '',
  //       graduationDate: '',
  //       university: '',
  //     },
  //   ]);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  return (
    <Typography component={'div'}>
      {fields && fields.length
        ? fields.map((item, index) => (
            <Grid
              container
              spacing={1}
              key={index}
              sx={
                fields.length === index + 1
                  ? {}
                  : {
                      borderBottom: '1px solid #ababff',
                      marginBottom: '10px',
                      paddingBottom: '10px',
                    }
              }>
              <Grid item md={3.3} sm={12}>
                <Typography component={'label'} className="input-title pb-2">
                  Degree
                </Typography>
                <Typography component={'div'}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`educations.${index}.degree`}
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          placeHolder=""
                          value={value}
                          name={name}
                          className="custom-Input"
                          ref={ref}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          error={
                            formState.errors?.educations?.[index]?.degree
                              ?.message
                          }
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={2} sm={12}>
                <Typography component={'label'} className="input-title pb-2">
                  Year Of Passing
                </Typography>
                <Typography component={'div'}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`educations.${index}.graduationYear`}
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomDatePicker
                          className="custom-datepicker"
                          name={name}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          value={value}
                          error={
                            formState.errors?.educations?.[index]
                              ?.graduationYear?.message
                          }
                          {...ref}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={3.3} sm={12}>
                <Typography component={'label'} className="input-title pb-2">
                  University
                </Typography>
                <Typography component={'div'}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`educations.${index}.university`}
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          placeHolder=""
                          value={value}
                          name={name}
                          className="custom-Input"
                          ref={ref}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          error={
                            formState.errors?.educations?.[index]?.university
                              ?.message
                          }
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={3.3} sm={12}>
                <Typography component={'label'} className="input-title pb-2">
                  Country
                </Typography>
                <Typography component={'div'}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`educations.${index}.country`}
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          placeHolder=""
                          value={value}
                          name={name}
                          className="custom-Input"
                          ref={ref}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          error={
                            formState.errors?.educations?.[index]?.country
                              ?.message
                          }
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          ))
        : null}
    </Typography>
  );
};
