import { Grid, Typography } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { getAllListData } from 'services/CommonServices';
import { GeneralDetails } from 'types';
import { MASTER_ENDPOINT, RESUME_ENDPOINT } from 'utils/Constant';
import {
  DocumentTypeInfo,
  DocumentTypeRespone,
  SourceInfo,
  SourceResponse,
  WorkAuthInfo,
  WorkAuthResponse,
} from 'utils/type';

type Props = {
  control: UseFormReturn<GeneralDetails>;
};
export const ProfileInfo: React.FC<Props> = (props: Props) => {
  const { control: formControl } = props;
  const { control, formState } = formControl;
  const [sourceList, setSourceList] = React.useState<Array<SourceInfo>>([]);
  const [workStatus, setWorkStatus] = React.useState<Array<WorkAuthInfo>>([]);
  const [documentType, setDocumentType] = React.useState<
    Array<DocumentTypeInfo>
  >([]);

  const getSourceList = () => {
    getAllListData(`${RESUME_ENDPOINT.ResumeValidationsources}`)
      .then((resp: SourceResponse) => {
        setSourceList(resp.data);
      })
      .catch(() => {
        setSourceList([]);
      });
  };
  const getWorkStatus = () => {
    getAllListData(`${MASTER_ENDPOINT.WorkStatus}?status=true`)
      .then((resp: WorkAuthResponse) => {
        setWorkStatus(resp?.data);
      })
      .catch(() => {
        setWorkStatus([]);
      });
  };
  const getDocumentType = () => {
    getAllListData(
      `${RESUME_ENDPOINT.ResumeValidationDocumentTypes}?status=true`,
    )
      .then((resp: DocumentTypeRespone) => {
        setDocumentType(resp?.data);
      })
      .catch(() => {
        setDocumentType([]);
      });
  };
  React.useEffect(() => {
    getSourceList();
    getWorkStatus();
    getDocumentType();
  }, []);

  return (
    <Typography component={'div'}>
      <Grid container spacing={1}>
        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            First Name
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="firstName"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomInput
                    placeHolder=""
                    value={value}
                    name={name}
                    className="custom-Input"
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    error={formState.errors?.firstName?.message}
                  />
                );
              }}
            />
          </Typography>
        </Grid>
        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            Last Name
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="lastName"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomInput
                    placeHolder=""
                    value={value}
                    name={name}
                    className="custom-Input"
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    error={formState.errors?.lastName?.message}
                  />
                );
              }}
            />
          </Typography>
        </Grid>
        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            Primary Email
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="primaryEmail"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomInput
                    placeHolder=""
                    value={value}
                    name={name}
                    className="custom-Input"
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    error={formState.errors?.primaryEmail?.message}
                  />
                );
              }}
            />
          </Typography>
        </Grid>
        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            City
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="city"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomInput
                    placeHolder=""
                    value={value}
                    name={name}
                    className="custom-Input"
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    error={formState.errors?.city?.message}
                  />
                );
              }}
            />
          </Typography>
        </Grid>
        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            State
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="state"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomInput
                    placeHolder=""
                    value={value}
                    name={name}
                    className="custom-Input"
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    error={formState.errors?.state?.message}
                  />
                );
              }}
            />
          </Typography>
        </Grid>
        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            Job Title
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="jobTitle"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomInput
                    placeHolder=""
                    value={value}
                    name={name}
                    className="custom-Input"
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    error={formState.errors?.jobTitle?.message}
                  />
                );
              }}
            />
          </Typography>
        </Grid>

        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            Phone Number
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="phoneNumber"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <PhoneInput
                    inputProps={{
                      name: name,
                      ...ref,
                    }}
                    inputClass="custom-phone-input"
                    country={formControl.getValues('countryCode') || 'us'}
                    inputStyle={{
                      border: '0px !important',
                    }}
                    value={value}
                    onChange={(val, data: any) => {
                      onChange(val);
                      formControl.setValue('countryCode', data?.dialCode);
                    }}
                  />
                );
              }}
            />
          </Typography>
        </Grid>

        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            Linkedin URL
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="linkedinUrl"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomInput
                    placeHolder=""
                    value={value}
                    name={name}
                    className="custom-Input"
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    error={formState.errors?.linkedinUrl?.message}
                  />
                );
              }}
            />
          </Typography>
        </Grid>

        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            Source
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="sourceId"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomSelect
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    value={value}
                    labelKey={'name'}
                    valueKey={'id'}
                    options={sourceList}
                    placeHolder={''}
                    className={'common-select'}
                    error={formState.errors?.sourceId?.message}
                    searchable={true}
                    name={name}
                    {...ref}
                  />
                );
              }}
            />
          </Typography>
        </Grid>

        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            Work Auth
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="workAuthId"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomSelect
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    value={value}
                    labelKey={'name'}
                    valueKey={'id'}
                    options={workStatus}
                    placeHolder={''}
                    className={'common-select'}
                    error={formState.errors?.workAuthId?.message}
                    searchable={true}
                    name={name}
                    {...ref}
                  />
                );
              }}
            />
          </Typography>
        </Grid>

        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            Document Type
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="documentTypeId"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomSelect
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    value={value}
                    labelKey={'name'}
                    valueKey={'id'}
                    options={documentType}
                    placeHolder={''}
                    className={'common-select'}
                    error={formState.errors?.documentTypeId?.message}
                    searchable={false}
                    name={name}
                    {...ref}
                  />
                );
              }}
            />
          </Typography>
        </Grid>
        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            Document Type Number
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="documentTypeNumber"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomInput
                    placeHolder=""
                    value={value}
                    name={name}
                    className="custom-Input"
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    error={formState.errors?.documentTypeNumber?.message}
                  />
                );
              }}
            />
          </Typography>
        </Grid>
      </Grid>
    </Typography>
  );
};
