import { Checkbox } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import DownIcon from 'assets/images/down-arrow-selected.svg';
import MoreIcon from 'assets/images/MoreIcon.svg';
import UpIcon from 'assets/images/up-arrow-selected.svg';
import InfinityScroll from 'components/InfinityScroll';
// import _ from 'lodash';
import ActiveModal from 'pages/common/ActiveModal';
import DeleteModal from 'pages/common/DeleteModal';
import AddAndIPwhitelist from 'pages/common/Ipwhitelist';
import React, { useEffect } from 'react';
import { getAllListData, updateData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { MASTER_ENDPOINT } from 'utils/Constant';

export const IPWhitelist = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [params, setParams] = React.useState<any>({
    order: 'asc',
    reload: true,
    skip: 0,
    sortby: 'ipName',
    status: true,
    take: 50,
  });
  const [loading, setLoading] = React.useState<any>(true);
  // const [statusFilter, setStatusFilter] = React.useState<any>(null);
  const [tableInfo, setTableInfo] = React.useState<any>({
    list: [],
    total: 0,
  });

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: any,
  ) => {
    setEditRow(row);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [editRow, setEditRow] = React.useState<any>({});
  const [editModal, setEditModal] = React.useState<any>({
    row: {},
    visible: false,
  });
  const handleOpen2 = () => {
    setEditModal({
      row: editRow,
      visible: true,
    });
    handleClose();
  };
  const handleClose2 = () =>
    setEditModal({
      row: {},
      visible: false,
    });
  const [deleteModal, setDeleteModal] = React.useState<any>({
    id: '',
    visible: false,
  });
  const handleOpen1 = () => {
    setDeleteModal({
      id: editRow.id,
      visible: true,
    });
    handleClose();
  };
  const handleClose1 = () =>
    setDeleteModal({
      id: '',
      visible: false,
    });

  const [activeModal, setActiveModal] = React.useState<any>({
    row: {},
    visible: false,
  });
  const handleOpen3 = () => {
    setActiveModal({
      row: editRow,
      visible: true,
    });
    handleClose();
  };
  const handleClose3 = () =>
    setActiveModal({
      row: {},
      visible: false,
    });
  const onDelete = () => {
    const ipPayload = {
      ipName: editRow.ipName,
      status: false,
    };
    updateData(deleteModal.id, ipPayload, MASTER_ENDPOINT.IpWhiteList, true)
      .then((resp: any) => {
        // const fInx = tableInfo.list.findIndex(
        //   (item: any) => item.id === deleteModal.id,
        // );
        // if (fInx !== -1) {
        //   tableInfo.list[fInx].status = false;
        //   setTableInfo({
        //     ...tableInfo,
        //   });
        // }
        getList();
        handleClose1();
      })
      .catch((err: any) => {});
  };

  const onActive = () => {
    const ipPayload = {
      ipName: activeModal.row.ipName,
      status: true,
    };
    updateData(
      activeModal.row.id,
      ipPayload,
      MASTER_ENDPOINT.IpWhiteList,
      true,
    ).then((resp: any) => {
      // const fInx = tableInfo.list.findIndex(
      //   (item: any) => item.id === activeModal.row.id,
      // );
      // if (fInx !== -1) {
      //   tableInfo.list[fInx].status = true;
      //   setTableInfo({
      //     ...tableInfo,
      //   });
      // }
      getList();
      handleClose3();
    });
  };

  const { searchText, reload }: any = useAppSelector(
    (store: any) => store.userLogin,
  );

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    setParams({ ...params, reload: !params.reload });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, reload]);

  const getList = () => {
    setLoading(true);
    setTableInfo({
      list: [],
      total: 0,
    });
    const url: any = `${MASTER_ENDPOINT.IpWhiteList}?order=${String(
      params.order,
    )}&sortby=${String(params.sortby)}&status=${
      params.status ? 'true' : ''
    }&search=${String(searchText)}&skip=${String(params.skip)}&take=${String(
      params.take,
    )}`;
    getAllListData(url)
      .then((resp: any) => {
        setLoading(false);
        setTableInfo({
          list: resp.data,
          total: resp?.total,
        });
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const headRow = React.useCallback(() => {
    return (
      <TableHead>
        <TableRow>
          <TableCell className="w-60" sx={{ paddingLeft: '30px !important' }}>
            <Typography component={'div'} className="d-flex-a">
              <Typography className="th-title">IP Address</Typography>
              <Typography className="sort-box">
                <img
                  src={UpIcon}
                  alt="up"
                  className="down-arrow"
                  onClick={() => {
                    if (params.order !== 'asc') {
                      setParams({ ...params, order: 'asc' });
                    }
                  }}
                />
                <img
                  src={DownIcon}
                  alt="up"
                  className="up-arrow"
                  onClick={() => {
                    if (params.order !== 'desc') {
                      setParams({ ...params, order: 'desc' });
                    }
                  }}
                />
              </Typography>
            </Typography>
          </TableCell>
          <TableCell className="w-20">
            <Typography component={'div'} className="d-flex-a">
              <Typography
                component={'div'}
                className="th-title"
                sx={{ paddingRight: '5px' }}>
                Show Active
              </Typography>
              <Typography component={'div'}>
                <Checkbox
                  color="success"
                  checked={params.status}
                  sx={{ padding: '0px' }}
                  onChange={(e) => {
                    setParams({
                      ...params,
                      status: e.target.checked,
                    });
                  }}
                />
              </Typography>
            </Typography>
          </TableCell>
          <TableCell className="w-5" />
        </TableRow>
      </TableHead>
    );
  }, [params]);

  const rowProps = React.useCallback((row: any, index: number) => {
    return (
      <TableRow
        key={index}
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
        }}>
        <TableCell>
          <Typography component={'div'} className="d-flex-a">
            <Typography className="td-text">{row?.ipName}</Typography>
          </Typography>
        </TableCell>
        <TableCell>
          <Typography component={'div'} className="d-flex-a">
            <Typography
              className="td-text"
              sx={{
                textTransform: 'capitalize',
              }}>
              {row?.status ? 'Active' : 'Inactive'}
            </Typography>
          </Typography>
        </TableCell>
        <TableCell>
          <Button
            sx={{
              minWidth: 'auto !important',
              padding: '0 10px !important',
            }}
            aria-describedby={id}
            onClick={(e) => handleClick(e, row)}>
            <img src={MoreIcon} alt="More" />
          </Button>
        </TableCell>
      </TableRow>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableData = React.useMemo(() => {
    return (
      <InfinityScroll
        paramState={setParams}
        scrollableTargetId={'IpWhiteList'}
        data={tableInfo.list}
        rowPorops={rowProps}
        headRow={headRow}
        count={tableInfo.total}
        columnCount={2}
        loading={loading}
        params={params}
      />
    );
  }, [headRow, rowProps, tableInfo.list, tableInfo.total, loading, params]);

  return (
    <Typography component={'div'}>
      <Grid container>
        <Grid item md={12}>
          <TableContainer component={Paper} className="recruiter-table">
            {tableData}
          </TableContainer>
        </Grid>
      </Grid>
      <Popover
        className="shadow-popover"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}>
        {editRow.status ? (
          <Typography component={'div'} className="inner-pop-text">
            <Typography className="arrow-top" />
            <Typography>
              <a onClick={handleOpen2}>Edit</a>
            </Typography>
            <Typography>
              <a onClick={handleOpen1}>Remove</a>
            </Typography>
          </Typography>
        ) : (
          <Typography component={'div'} className="inner-pop-text">
            <Typography className="arrow-top" />
            <Typography>
              <a onClick={handleOpen3}>Active</a>
            </Typography>
          </Typography>
        )}
      </Popover>
      <AddAndIPwhitelist
        row={editModal.row}
        editId={editModal.row?.id}
        visible={editModal.visible}
        onClose={handleClose2}
      />
      <DeleteModal
        visible={deleteModal.visible}
        onClose={handleClose1}
        onDone={onDelete}
        title="IP Address"
      />
      <ActiveModal
        visible={activeModal.visible}
        onClose={handleClose3}
        onDone={onActive}
        title="IP Address"
      />
    </Typography>
  );
};
