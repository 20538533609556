/* eslint-disable react/jsx-no-useless-fragment */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';
import CustomButton from 'components/CustomButton/customButton';
import { Loader } from 'components/loader';
import React from 'react';
import { useForm } from 'react-hook-form';
import {
  addNewData,
  getAllListData,
  ReplaceData,
} from 'services/CommonServices';
import {
  CreateCandidateApiResponse,
  CreateCandidateparserData,
  EducationInfo,
  ExperienceInfo,
  GeneralDetails,
  GeneralFormDate,
} from 'types';
import { RESUME_ENDPOINT } from 'utils/Constant';
import { StatusData } from 'utils/type';
import { AddGeneralInfoSchema } from 'utils/ValidatorSchema';

import { AddNotes } from '../Notes/AddNotes';
import { AccordionView } from './Accordion';

type Props = {
  close?: (status: string) => void;
  resumeId: string;
  candidateId: string;
};

export const accordionTypes = [
  {
    name: 'Personal Information',
    type: 'profileInfo',
  },
  {
    name: 'Experience Information',
    type: 'experienceInfo',
  },
  {
    name: 'Education Information',
    type: 'educationInfo',
  },
  {
    name: 'Skills',
    type: 'skils',
  },
];
export const CreateCandidate: React.FC<Props> = (props: Props) => {
  const { close, resumeId, candidateId } = props;
  const [done, setdone] = React.useState(false);
  const [loading, setLoading] = React.useState<{
    button: boolean;
    common: boolean;
  }>({
    button: false,
    common: true,
  });
  const ReasonRef = React.useRef<any>(null);

  function handleReason() {
    ReplaceData(
      resumeId,
      {
        comments: ReasonRef.current.value,
        status: StatusData.Discarded,
      },
      RESUME_ENDPOINT.ResumeValidationStatus,
      true,
    )
      .then((er: any) => {
        close(undefined);
      })
      .catch((er) => console.warn(er));
  }

  const ValueChecker = (MyLocaldata: any, value: any): any => {
    if (MyLocaldata && MyLocaldata[value]) {
      return MyLocaldata[value];
    } else {
      return null;
    }
  };

  const formControl = useForm<GeneralDetails>({
    defaultValues: {
      city: '',
      countryCode: '',
      documentTypeId: '',
      documentTypeNumber: '',
      educations: [],
      experience: [],
      firstName: '',
      jobTitle: '',
      lastName: '',
      linkedinUrl: '',
      notes: '',
      phoneNumber: '',
      primaryEmail: '',
      skills: [],
      sourceId: '',
      state: '',
      workAuthId: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(AddGeneralInfoSchema),
  });
  const submit = (formData: GeneralDetails) => {
    const bodyData: GeneralFormDate = {
      ...formData,
      candidateId: candidateId,
      educationInformation: formData.educations,
      experienceInformation: formData.experience,
      notes: [{ note: formData.notes }],
      resumeId: resumeId,
      skills: Array.from(new Set(formData.skills.map((e) => e.name))),
    };
    setLoading((pre) => ({
      ...pre,
      button: true,
    }));
    addNewData(bodyData, RESUME_ENDPOINT.ResumeValidationSave, {}, true)
      .then(() => {
        setLoading((pre) => ({
          ...pre,
          button: false,
        }));
        close && close(StatusData.REVIEWED);
      })
      .catch(() => {
        setLoading((pre) => ({
          ...pre,
          button: false,
        }));
      });
  };

  const getMatchedProfile = React.useCallback(() => {
    const { setValue } = formControl;
    getAllListData(
      `${RESUME_ENDPOINT.ResumeValidationParsed}/${String(resumeId)}`,
      true,
    )
      .then((resp: CreateCandidateApiResponse) => {
        if (resp && resp.data && resp.data.file && resp.data.file.parserData) {
          const MyLocaldata: CreateCandidateparserData =
            resp.data.file.parserData;
          setValue('firstName', ValueChecker(MyLocaldata, 'firstName'));
          setValue('lastName', ValueChecker(MyLocaldata, 'lastName'));
          setValue('primaryEmail', ValueChecker(MyLocaldata, 'primaryEmail'));
          setValue('city', ValueChecker(MyLocaldata, 'city'));
          setValue('state', ValueChecker(MyLocaldata, 'state'));
          setValue('jobTitle', ValueChecker(MyLocaldata, 'jobTitle'));
          setValue(
            'phoneNumber',
            `${(ValueChecker(MyLocaldata, 'countryCode') || '') as string}${
              (ValueChecker(MyLocaldata, 'phoneNumber') || '') as string
            }`,
          );
          setValue('linkedinUrl', ValueChecker(MyLocaldata, 'linkedinUrl'));
          setValue('sourceId', ValueChecker(MyLocaldata, 'source'));
          setValue('workAuthId', ValueChecker(MyLocaldata, 'workAuth'));
          setValue('countryCode', ValueChecker(MyLocaldata, 'countryCode'));

          if (ValueChecker(MyLocaldata, 'projects')) {
            setValue(
              'experience',
              ValueChecker(MyLocaldata, 'projects').map(
                (exp: ExperienceInfo) => {
                  return {
                    employerOrClient: ValueChecker(exp, 'companyName'),
                    fromDate: ValueChecker(exp, 'fromDate'),
                    isCurrentlyWorking:
                      ValueChecker(exp, 'isCurrentlyWorking') || false,
                    jobTitle: ValueChecker(exp, 'designation'),
                    location: ValueChecker(exp, 'location'),
                    project: ValueChecker(exp, 'projectTitle'),
                    toDate: ValueChecker(exp, 'toDate'),
                  };
                },
              ),
            );
          }
          if (ValueChecker(MyLocaldata, 'education')) {
            setValue(
              'educations',
              ValueChecker(MyLocaldata, 'education').map(
                (edu: EducationInfo) => {
                  return {
                    country: ValueChecker(edu, 'country'),
                    degree: ValueChecker(edu, 'degree'),
                    graduationYear: ValueChecker(edu, 'yearOfPassing'),
                    university: ValueChecker(edu, 'university'),
                  };
                },
              ),
            );
          }
          const skils = ValueChecker(MyLocaldata, 'skill');
          if (skils && skils.length) {
            setValue(
              'skills',
              Array.from(new Set(skils)).map((val: string, idt: number) => {
                return {
                  id: idt.toString(),
                  name: val ? val : null,
                  rowId: undefined,
                };
              }),
            );
          }
        }

        setLoading((pre) => ({
          ...pre,
          common: false,
        }));
      })
      .catch(() => {
        setLoading((pre) => ({
          ...pre,
          common: false,
        }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resumeId]);

  const handleDone = () => {
    setdone((p) => !p);
  };
  React.useEffect(() => {
    setLoading((pre) => ({
      ...pre,
      common: true,
    }));
    getMatchedProfile();
  }, [getMatchedProfile]);

  return (
    <Typography component={'div'}>
      <>
        <Typography component={'div'} className="d-flex-a flex-sb plr-14 pb-4">
          <Typography
            component={'div'}
            className="red-color resume-tab-header highlight pl-0">
            {'General Details'}
          </Typography>
        </Typography>
        {loading.common ? (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              height: 'calc(88vh - 75px)',
              justifyContent: 'center',
            }}>
            <Loader
              pageCenterLoadingHide={true}
              className={'d-flex-ja w-100 h-100'}
            />
          </Box>
        ) : (
          <Box>
            <Typography
              component={'div'}
              className="pr-5"
              sx={{ height: 'calc(88vh - 75px)', overflow: 'auto' }}>
              <div
                style={{
                  opacity: done ? 0.12 : 1,
                  pointerEvents: done ? 'none' : 'auto',
                }}
                onClick={(eg: any) => {
                  if (done) {
                    eg.preventDefault();
                    return;
                  }
                }}>
                <AddNotes setValue={formControl.setValue} hideButton={true} />

                <form
                  onSubmit={formControl.handleSubmit(submit)}
                  id="generalForm">
                  {accordionTypes.map((e) => (
                    <AccordionView
                      key={e.type}
                      header={e.name}
                      type={e.type}
                      control={formControl}
                      closeAcc={done ? true : false}
                    />
                  ))}
                </form>
              </div>
              {done ? (
                <div className="reasoncontainer">
                  <div className="inputContainer">
                    <div className="label">Reason for Discarding</div>
                    <textarea cols={30} rows={30} ref={ReasonRef} />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </Typography>
            {done ? (
              <div className="reasonBtnContainer">
                <div className="btn bt1" onClick={handleDone}>
                  cancel
                </div>
                <div
                  className="btn bt2"
                  onClick={() => {
                    ReasonRef.current && ReasonRef.current.value
                      ? handleReason()
                      : undefined;
                  }}>
                  Save
                </div>
              </div>
            ) : (
              <></>
            )}
            {done ? (
              <></>
            ) : (
              <Typography
                component={'div'}
                className="d-flex-jae"
                sx={{ marginTop: '20px' }}>
                <div
                  className="discardbtn"
                  onClick={handleDone}
                  style={{ marginRight: '26px' }}>
                  Discard
                </div>
                <CustomButton
                  variant="contained"
                  type={'submit'}
                  isStyle={true}
                  name={'Submit'}
                  loading={loading.button}
                  form={'generalForm'}
                  className={'GeneralDetails'}
                />
              </Typography>
            )}
          </Box>
        )}
      </>
    </Typography>
  );
};
