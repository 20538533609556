/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  Box,
  Checkbox,
  CircularProgress,
  Drawer,
  // FormControlLabel,
  FormHelperText,
} from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
// import { styled } from '@mui/material/styles';
// import Slider from '@mui/material/Slider';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import closefilter from 'assets/images/closefilter.svg';
import dangerIcon from 'assets/images/dangerIcon.svg';
import documentDownload from 'assets/images/document-download.svg';
import greenflag from 'assets/images/greenflag.svg';
// import { LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import {
//   CalendarPicker,
//   CalendarPickerView,
// } from '@mui/x-date-pickers/CalendarPicker';
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import CheckIcon from 'assets/images/lightgreencheck.svg';
import redcheckbox from 'assets/images/redcheckbox.svg';
import redfilter from 'assets/images/redfilter.svg';
import redflag from 'assets/images/redflag.svg';
import yellowflag from 'assets/images/yellowflag.svg';
import InfinityScroll from 'components/InfinityScroll';
import { ViewJobConstants } from 'config';
import enUS from 'date-fns/locale/en-US'; // Ensure locale import
import { capitalize } from 'lodash';
import _ from 'lodash';
// import _ from 'lodash';
import debouce from 'lodash.debounce';
import moment from 'moment';
import React, {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DateRange } from 'react-date-range';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addNewData, getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { timeSince } from 'utils/ArrayConstants';
import { alertOptions } from 'utils/Config';
import { RESUME_ENDPOINT } from 'utils/Constant';
import { Formatify } from 'utils/Helpers';
import {
  CheckBoxId,
  LoadersState,
  StatusData,
  TableInfo,
  UserFilterList,
} from 'utils/type';

import DiscardModalPopup from '../DiscardModalPopup';
import { ResumeReview } from '../ResumeReview';
import UploadResumeModal from '../UploadResumeModal';
import ResumeSearchBar from './ResumeSearchBar';
import ResumeValSearchFilter from './ResumeValSearchFilter';

// const FixedCalendarContainer = styled('div')(({ theme }) => ({
//   backgroundColor: theme.palette.background.paper,
//   boxShadow: theme.shadows[5],
//   padding: theme.spacing(2),
//   position: 'fixed',
//   right: 0,
//   top: 0,
//   zIndex: theme.zIndex.modal + 1,
// }));
// import useStyles from '../../../pages/private/PrivateStyle';

const staticParam = {
  fromDate: '',
  order: 'desc',
  reload: true,
  skip: 0,
  sortby: '',
  take: 50,
  toDate: '',
  uploadedBy: '',
};
interface Props {
  placeHolder?: string;
  type?: string;
  jobPostingId?: string;
  resumes?: boolean;
  jobData?: any;
}
export interface childProps {
  getUpdatedResume: () => void;
}
export const ResumeValidationTable = forwardRef<childProps, Props>(
  (props: Props, ref: Ref<childProps>) => {
    const { placeHolder } = props;

    const [viewModal, setViewModal] = useState<{
      fileId: string | null;
      show: boolean;
      resumeId: string | null;
    }>({
      fileId: null,
      resumeId: null,
      show: false,
    });
    const [checkedIds, setCheckedIds] = useState<CheckBoxId[]>([]);
    const [dateSelection, setDateSelection] = useState([
      {
        // endDate: new Date(),
        endDate: null,
        key: 'selection',
        // startDate: new Date(),
        startDate: null,
      },
    ]);
    const [DiscardVal, setDiscardVal] = useState<any>(undefined);
    const isDateSelected =
      dateSelection[0].startDate && dateSelection[0].endDate;
    // const [error, setError] = useState<string>('');
    // const [searchText, setSearchText] = useState<string>('');
    // const [uploadedBySearch, setUploadedBySearch] = useState<string>('');
    // const [openfilter, setOpenFilter] = useState<boolean>(false);
    const [opening1, setopening1] = useState<boolean>(false);
    const [JobListingData, setJobListingData] = useState<any>([
      { label: 'Select a source of the resume', value: undefined },
    ]);
    const [params, setParams] = useState<any>(staticParam);
    const [opening, setopening] = useState<boolean>(false);
    const [loading, setLoading] = useState<LoadersState>({
      filterLoader: false,
      tableLoader: false,
    });
    const [DiscardLoader, setDiscardLoader] = useState<boolean>(false);
    const [SuccessUPRESLoader, setSuccessUPRESLoader] =
      useState<boolean>(false);
    const [ResumeuploadLoader, setResumeuploadLoader] =
      useState<boolean>(false);
    const [tableInfo, setTableInfo] = useState<TableInfo>({
      list: [],
      searchText: '',
      total: 10,
    });
    const [userFilterList, setUserFilterList] = useState<UserFilterList>({
      error: '',
      list: [],
      open: false,
      uploadedBySearch: '',
    });

    const history = useNavigate();
    const jobData = useRef(null);
    const { userInfo, jobTab }: any = useAppSelector(
      (store: any) => store.userLogin,
    );

    // const Formatify = (str: string) =>
    //   str.split(' ').join('').replaceAll(/\s/g, '').toLowerCase();
    function handlesetDiscardLoader() {
      setDiscardLoader((p: boolean) => !p);
    }
    function CloseFn() {
      setopening((p) => !p);
      setSuccessUPRESLoader(false);
      if (jobData && jobData.current) {
        jobData.current = null;
      }
    }

    const handleClickView = (id?: string, item?: any, resumes1?: boolean) => {
      const isJobEdit = {
        id: id && id !== '' ? id : undefined,
        item: item && !_.isEmpty(item) ? item : undefined,
      };
      const Resumes1 = resumes1
        ? ViewJobConstants.Resumes
        : ViewJobConstants.myPosting;

      if (userInfo.roleId === 4) {
        history(`/marketer/job-post/view-job/${Resumes1}/` + isJobEdit.id);
      }
      if (userInfo.roleId === 3) {
        if (isJobEdit.item?.status === StatusData.PUBLISHED) {
          history(`/recruiter/job-post/view-job/${Resumes1}/` + isJobEdit.id);
        } else {
          history(`/recruiter/job-post/view-job/${Resumes1}/` + isJobEdit.id);
        }
      }
      if (userInfo.roleId === 5) {
        if (
          isJobEdit.item?.user?.id === userInfo.id &&
          jobTab === 0 &&
          isJobEdit.item?.status === StatusData.PUBLISHED
        ) {
          history(`/sales/job-post/view-job/${Resumes1}/${isJobEdit.id}`);
        } else {
          history(`/sales/job-post/view-job/${Resumes1}/` + isJobEdit.id);
        }
      }
    };

    function CloseFn1() {
      setopening1((p) => !p);
    }

    function ResumeSubmit1() {
      console.log('jjjj');
    }

    async function ListApiCall() {
      setResumeuploadLoader(true);
      try {
        const resp = await getAllListData(
          `${RESUME_ENDPOINT.ResumeValidationsources}`,
        );
        if (resp && resp.data) {
          setJobListingData(resp.data);
        }
      } catch (error) {
        console.log(error, 'error');
      } finally {
        setResumeuploadLoader(false);
      }
    }

    async function ResumeSubmit(sourceId: any, Files: any, ResumeId?: any) {
      const formData = new FormData();
      formData.append('resumeId', ResumeId && ResumeId.id ? ResumeId.id : '');
      formData.append('sourceId', sourceId);
      formData.append('file', Files && Files[0]);
      // formData.append('jobPostingId', jobId);
      formData.append(
        'jobPostingId',
        ResumeId && ResumeId.type === 'jobResume' && ResumeId.jobPostingId
          ? ResumeId.jobPostingId
          : '',
      );
      formData.append('type', ResumeId && ResumeId.type ? ResumeId.type : '');
      // console.log(formData, 'formData');
      setResumeuploadLoader(true);
      setSuccessUPRESLoader(false);

      try {
        const resp = await addNewData(
          formData,
          RESUME_ENDPOINT.ResumeValidation,
          {},
          false,
        );
        // console.log(resp, 'resp');
        if (resp) {
          setSuccessUPRESLoader(true);
          setResumeuploadLoader(false);
        }
      } catch (error) {
        setSuccessUPRESLoader(false);
        toast.error(error, alertOptions);
      } finally {
        setResumeuploadLoader(false);
        // console.log(SuccessUPRESLoader, 'successloader');
      }
    }

    const resetDefaultValue = (type?: string) => {
      if (type === 'date') {
        setParams((pre) => ({
          ...pre,
          fromDate: '',
          skip: 0,
          toDate: '',
          uploadedBy: '',
        }));
        setUserFilterList((pre) => ({
          ...pre,
          open: false,
          uploadedBySearch: '',
        }));
      } else {
        setCheckedIds([]);
        setDateSelection([
          {
            // endDate: new Date(),
            endDate: null,
            key: 'selection',
            // startDate: new Date(),
            startDate: null,
          },
        ]);
        setUserFilterList((pre) => ({
          ...pre,
          error: '',
          open: false,
          uploadedBySearch: '',
        }));
      }
    };

    const handleCheckboxChange = (id: string, isChecked: boolean) => {
      setCheckedIds((prevCheckedIds) => {
        if (isChecked) {
          return [...prevCheckedIds, id];
        } else {
          return prevCheckedIds.filter((checkedId) => checkedId !== id);
        }
      });
    };

    const handleChange = (e) => {
      setTableInfo((pre) => ({
        ...pre,
        searchText: e.target.value,
      }));
      // setSearchText(e.target.value);
      setParams((pre) => {
        return { ...pre, reload: !params.reload, skip: 0, take: 50 };
      });
    };

    const debouncedResults = useMemo(() => {
      return debouce(handleChange, 1000);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getResumeValidations = () => {
      setLoading((pre) => ({ ...pre, tableLoader: true }));
      setTableInfo((pre) => ({
        ...pre,
        list: [],
        total: 0,
      }));
      getAllListData(
        `${RESUME_ENDPOINT.ResumeValidationResumes}?skip=${String(
          params.skip,
        )}&take=${String(params.take)}&order=${String(
          params.order,
        )}&search=${String(tableInfo.searchText)}&sortby=${String(
          params.sortby,
        )}&fromDate=${String(params.fromDate)}&toDate=${String(
          params.toDate,
        )}&uploadedBy=${params.uploadedBy}${
          props.resumes && props.type && props.jobPostingId
            ? `&type=${props.type}&jobPostingId=${props.jobPostingId}`
            : ''
        }`,
      )
        .then((res) => {
          setTableInfo((pre) => ({
            ...pre,
            list: res.data,
            total: res.total,
          }));
          setLoading((pre) => ({ ...pre, tableLoader: false }));
        })
        .catch((err) => {
          setLoading((pre) => ({ ...pre, tableLoader: false }));
          console.log(err);
          // toast.error(err, alertOptions);
        });
    };

    const getResumeUsers = () => {
      setLoading((pre) => ({ ...pre, filterLoader: true }));
      getAllListData(`${RESUME_ENDPOINT.ResumeValidationUsers}`)
        .then((res) => {
          setUserFilterList((pre) => ({
            ...pre,
            list: res.data,
          }));
          setLoading((pre) => ({ ...pre, filterLoader: false }));
        })
        .catch((err) => {
          setLoading((pre) => ({ ...pre, filterLoader: false }));
          console.log(err);
          // toast.error(err, alertOptions);
        });
    };

    const filteredUserList = userFilterList.list.filter((user) => {
      const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
      return fullName.includes(userFilterList.uploadedBySearch.toLowerCase());
    });

    const handleDateChange = (item) => {
      setDateSelection([item.selection]);
      const { startDate, endDate } = item.selection;
      if (startDate && endDate) {
        // if (startDate < endDate) {
        setUserFilterList((pre) => ({
          ...pre,
          error: '',
        }));
        // setError('');
        // if (startDate < endDate) {
        //   setUserFilterList((pre) => ({
        //     ...pre,
        //     error: '',
        //   }));
        //   // setError('');
        // } else {
        //   setUserFilterList((pre) => ({
        //     ...pre,
        //     error: 'End date must be after start date.',
        //   }));
        //   // setError('End date must be after start date.');
        // }
      } else {
        setUserFilterList((pre) => ({
          ...pre,
          error: 'Please select date.',
        }));
        // else {
        //   setUserFilterList((pre) => ({
        //     ...pre,
        //     error: 'Please select both start and end dates.',
        //   }));

        // setError('Please select both start and end dates.');
      }
    };

    const validateDateSelection = () => {
      const { startDate, endDate } = dateSelection[0];

      return new Promise((resolve, reject) => {
        if (userInfo.roleId === 3) {
          if (startDate && endDate) {
            // if (startDate < endDate) {
            resolve(true);
            // if (startDate < endDate) {
            //   resolve(true);
            // } else {
            //   reject('End date must be after start date.');
            // }
          } else {
            // reject('End date must be after start date.');
            reject('Please select date.');
          }
        } else {
          // reject('Please select both start and end dates.');
          resolve(true);
        }
      });
    };

    const headRow = useCallback(() => {
      return (
        <TableHead>
          <TableRow
          //  sx={{
          //   textTransform: 'unset !important',
          // }}
          >
            <TableCell className="w-5" align="center">
              S.No
            </TableCell>
            <TableCell className="w-10">Customer</TableCell>
            <TableCell className="w-15">Job Title</TableCell>
            <TableCell className="w-15">Name of the candidate</TableCell>
            <TableCell className="w-15">uploaded date</TableCell>
            <TableCell className="w-15">uploaded by</TableCell>
            <TableCell className="w-15" align="center">
              Status
            </TableCell>
            <TableCell className="w-5" />
            <TableCell className="w-15">Resume Status</TableCell>
          </TableRow>
        </TableHead>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const rowProps = useCallback(
      (row: any, index: number) => {
        return (
          <TableRow
            key={index}
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
              cursor: 'pointer',
            }}
            onClick={() => {
              jobData.current =
                (row && row.job) || (row && row.source)
                  ? {
                      job: row.job ? row.job : undefined,
                      source: row.source ? row.source : undefined,
                    }
                  : undefined;
            }}>
            <TableCell align="center">
              {Number(params ? params.skip || 0 : 0) + Number(index) + 1 + '.'}
            </TableCell>
            <TableCell
              onClick={(e: any) => {
                e.stopPropagation();
                row && row.jobPostingId
                  ? handleClickView(row.jobPostingId, undefined, true)
                  : '';
              }}>
              {row?.job?.customer?.name || '-'}
            </TableCell>
            <TableCell
              className="pointer"
              onClick={(e: any) => {
                e.stopPropagation();
                row && row.jobPostingId
                  ? handleClickView(row.jobPostingId, undefined, true)
                  : '';
              }}>
              {row?.job?.jobTitle || '-'}
            </TableCell>
            <TableCell>
              {row?.firstName && row?.lastName
                ? `${row?.firstName} ${row?.lastName}`
                : row?.firstName
                ? row?.firstName
                : '-'}
            </TableCell>
            <TableCell>
              {row?.createdAt
                ? `${moment(row?.createdAt).format('MM/DD/YYYY h:mmA')}`
                : '-'}
            </TableCell>
            <TableCell>
              {row?.uploadedBy?.firstName && row?.uploadedBy?.lastName
                ? `${row?.uploadedBy?.firstName} ${row?.uploadedBy?.lastName}`
                : row?.uploadedBy?.firstName
                ? row?.uploadedBy?.firstName
                : '-'}
            </TableCell>
            <TableCell>
              <Typography
                component={'div'}
                onClick={() =>
                  (userInfo.roleId === 3 &&
                    row?.status === StatusData.READYFORREVIEW) ||
                  (userInfo.roleId === 5 && row?.status === StatusData.REVIEWED)
                    ? setViewModal((pre) => ({
                        ...pre,
                        fileId: row?.fileId,
                        resumeId: row?.id,
                        show: true,
                      }))
                    : setViewModal((pre) => ({
                        ...pre,
                        fileId: null,
                        resumeId: null,
                        show: false,
                      }))
                }
                sx={{
                  backgroundColor:
                    row?.status === StatusData.ACCEPTED ||
                    row?.status === StatusData.REVIEWED
                      ? '#6AB8FF'
                      : row?.status === StatusData.INPROGRESS
                      ? '#FCEC11'
                      : row?.status === StatusData.READYFORREVIEW
                      ? '#F2FFD8'
                      : row?.status === StatusData.REJECTED
                      ? '#bf0000'
                      : '#FF0000',
                  borderRadius: '20px',
                  // color: '#FF0000', // RFR text RED color
                  // color: '#FFFFFF', //Revierwd tet color
                  // color: '#FFFFFF', // accepted  text white
                  // color:'#7C7630'  //yellow text color inProcess
                  color:
                    row?.status === StatusData.ACCEPTED ||
                    row?.status === StatusData.REVIEWED
                      ? '#FFFFFF'
                      : row?.status === StatusData.INPROGRESS
                      ? '#000'
                      : row?.status === StatusData.REJECTED
                      ? '#FFFFFF'
                      : row?.status === StatusData.PARSINGFAILED
                      ? '#FF0000'
                      : row?.status === StatusData.READYFORREVIEW
                      ? '#7fbc01'
                      : '#FFFFFF',

                  fontFamily: 'Red Hat Display SemiBold !important',
                  fontSize: '15px',
                  padding: '6px',
                  textAlign: 'center',
                  textTransform: 'unset !important',
                  ...(row?.status === StatusData.PARSINGFAILED && {
                    display: 'flex',
                    justifyContent: 'space-evenly',
                  }),
                }}>
                {row?.status === StatusData.PARSINGFAILED && (
                  <img src={dangerIcon} alt="dangerIcon" />
                )}
                {row?.status && row?.status === StatusData.INPROGRESS
                  ? 'In process'
                  : row?.status === StatusData.READYFORREVIEW
                  ? 'Ready for review'
                  : row?.status}
              </Typography>
            </TableCell>
            <TableCell sx={{ cursor: 'pointer', textAlign: 'center' }}>
              {row?.status === StatusData.PARSINGFAILED ? (
                <img
                  src={documentDownload}
                  alt="documentDownload"
                  onClick={() => {
                    row && row.id ? setDiscardVal(row) : undefined;
                    setopening1(true);
                  }}
                />
              ) : (
                '-'
              )}
            </TableCell>
            <TableCell align="center">
              {row?.status === StatusData.ACCEPTED ? (
                <img src={greenflag} alt="greenflag" />
              ) : row?.status === StatusData.REVIEWED ? (
                <img src={redflag} alt="redflag" />
              ) : row?.status === StatusData.REJECTED ? (
                <img src={yellowflag} alt="yellowflag" />
              ) : (
                '-'
              )}
            </TableCell>
          </TableRow>
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [params.skip],
    );

    const tableData = useMemo(() => {
      return (
        <InfinityScroll
          paramState={setParams}
          scrollableTargetId={''}
          data={tableInfo.list}
          rowPorops={rowProps}
          headRow={headRow}
          count={tableInfo.total}
          columnCount={12}
          loading={loading.tableLoader}
          params={params}
        />
      );
    }, [
      params,
      tableInfo.list,
      tableInfo.total,
      rowProps,
      headRow,
      loading.tableLoader,
    ]);

    useEffect(() => {
      ListApiCall();
    }, []);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    useEffect(() => {
      getResumeValidations();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    useEffect(() => {
      getResumeUsers();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //   if (Object.keys(params).length) {
    //     setParams({ ...staticParam, reload: !params.reload });
    //   } else {
    //     setParams(staticParam);
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [searchText]);
    // searchText
    //  background: #F2FFD8;  - R F R
    // background: #FCEC11;  - I P
    // background: #6AB8FF;  - Accept
    // background: #FF0000;  - RFR color
    useImperativeHandle(ref, () => ({
      getUpdatedResume() {
        setParams((pre) => ({
          ...pre,
          skip: 0,
          take: 50,
        }));
      },
    }));
    const jobDataVal = jobData.current;
    const jobDataVal2 =
      props.resumes && props.jobData ? props.jobData : undefined;
    return (
      <Typography component={'div'}>
        <DiscardModalPopup
          open={opening1}
          ClickFn={CloseFn1}
          SubmitFn={ResumeSubmit1}
          OpenUploadModal={() => {
            CloseFn1();
            setopening(true);
          }}
          DiscardValue={DiscardVal}
          Loader={DiscardLoader}
          LoaderFn={handlesetDiscardLoader}
        />
        <UploadResumeModal
          open={opening}
          ClickFn={CloseFn}
          SubmitFn={ResumeSubmit}
          Success={SuccessUPRESLoader}
          Loader={ResumeuploadLoader}
          JobListingData={JobListingData}
          ResumeId={DiscardVal}
          ReadyMadeSourceId={
            jobDataVal && jobDataVal.source && jobDataVal.source.id
              ? jobDataVal.source.id
              : ''
          }
          JobTitle={
            jobDataVal && jobDataVal.job && jobDataVal.job.jobTitle
              ? jobDataVal.job.jobTitle
              : ''
          }
          Customer={
            jobDataVal && jobDataVal.source && jobDataVal.source.name
              ? jobDataVal.source.name
              : ''
          }
          headerShown={
            jobDataVal &&
            jobDataVal.job &&
            jobDataVal.job.jobTitle &&
            jobDataVal.source &&
            jobDataVal.source.name
          }
        />
        <Grid container>
          <Grid item md={12}>
            <Typography component={'div'} className="recruiter-body">
              <Typography component={'div'} className="d-flex-a flex-sb">
                {props.resumes ? (
                  <Typography className="Resumes-validation-container">
                    {jobDataVal2 && Object.values(jobDataVal2).length > 0 ? (
                      <header>
                        <div className="firstbox">
                          <div className="txt">
                            {jobDataVal2 && jobDataVal2.jobTitle
                              ? jobDataVal2.jobTitle
                              : ''}
                          </div>
                          <div
                            className={`btn ${
                              jobDataVal2 &&
                              jobDataVal2.status &&
                              Formatify(jobDataVal2.status) ===
                                StatusData.PUBLISHED
                                ? 'post-badge'
                                : Formatify(jobDataVal2.status) ===
                                  StatusData.CLOSED
                                ? 'tick-badge'
                                : 'match-badge'
                            }`}>
                            {jobDataVal2 &&
                            jobDataVal2.status &&
                            Formatify(jobDataVal2.status) ===
                              StatusData.CLOSED ? (
                              <img src={CheckIcon} alt="Tick" />
                            ) : (
                              ''
                            )}
                            <span>
                              {jobDataVal2 &&
                              jobDataVal2.status &&
                              Formatify(jobDataVal2.status) ===
                                StatusData.PUBLISHED
                                ? 'Posted'
                                : Formatify(jobDataVal2.status) ===
                                  StatusData.CLOSED
                                ? 'Filled'
                                : jobDataVal2 &&
                                  jobDataVal2.status &&
                                  jobDataVal2.status
                                ? capitalize(jobDataVal2.status)
                                : ''}
                            </span>
                          </div>
                        </div>
                        <div className="secondbox">
                          <div className="txt">
                            <span className="texted company">
                              {jobDataVal2 &&
                              jobDataVal2.customer &&
                              jobDataVal2.customer.name
                                ? jobDataVal2.customer.name
                                : ''}
                            </span>{' '}
                            <span className="texted location">
                              {jobDataVal2 && jobDataVal2.city
                                ? jobDataVal2.city
                                : ''}
                              ,{' '}
                              {jobDataVal2 && jobDataVal2.state
                                ? jobDataVal2.state
                                : ''}{' '}
                              (
                              {jobDataVal2 && jobDataVal2.workType
                                ? jobDataVal2.workType
                                : ''}
                              )
                            </span>{' '}
                            <span className="month">
                              {jobDataVal2 && jobDataVal2.createdAt
                                ? timeSince(jobDataVal2.createdAt)
                                : 0}{' '}
                              ago
                            </span>
                          </div>
                        </div>
                      </header>
                    ) : (
                      ''
                    )}
                  </Typography>
                ) : (
                  <Typography className="recruter-h1">
                    Resume Validation
                  </Typography>
                )}
                <Typography component={'div'} className="d-flex-a">
                  <ResumeSearchBar
                    placeHolderText={`Search by ${
                      placeHolder
                        ? placeHolder
                        : 'Customer,Candidate Name and Job Title'
                    } `}
                    onChange={debouncedResults}
                  />
                  <Button
                    onClick={() => {
                      setUserFilterList((pre) => ({
                        ...pre,
                        open: true,
                      }));
                      // setOpenFilter(true);
                    }}>
                    <img
                      src={redfilter}
                      alt="Filter"
                      style={{
                        height: '18px',
                        width: '30px',
                      }}
                    />
                  </Button>
                  {/* <Typography className="resume-val-clear-all" onClick={() => {}}>
                  Clear All
                </Typography> */}
                  <Drawer
                    className="resume-validation-filter-drawer"
                    open={userFilterList.open}
                    anchor="right"
                    onClose={() =>
                      //  setOpenFilter(false)
                      setUserFilterList((pre) => ({
                        ...pre,
                        open: true,
                      }))
                    }>
                    <Typography
                      className="resume-validation-filter-title"
                      sx={{
                        alignItems: 'center !important',
                        color: '#000000',
                        display: 'flex',
                        fontFamily: 'Red Hat Display',
                        fontSize: '25px !important',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        justifyContent: 'space-between',
                        lineHeight: '33px',
                        padding: '16px',
                      }}>
                      Filter
                      <img
                        src={closefilter}
                        alt="closefilter"
                        style={{
                          cursor: 'pointer',
                          height: '18.23px',
                          width: '18px',
                          // color: '#242225 !important',
                          // fontSize: '39px !important',
                          // position: 'absolute',
                          // right: '16px',
                          // top: '1.64px',
                        }}
                        onClick={() =>
                          //  setOpenFilter(false)
                          setUserFilterList((pre) => ({
                            ...pre,
                            open: false,
                          }))
                        }
                      />
                    </Typography>
                    {userInfo?.roleId === 5 && (
                      <Box
                        className="res-val-filter-box"
                        // sx={{
                        //   display: 'flex',
                        //   flexDirection: 'column',
                        //   padding: '16px',
                        //   paddingBottom: '0px',
                        //   paddingTop: '0px',
                        // }}
                      >
                        <ResumeValSearchFilter
                          staticWidth
                          onChange={
                            (e) =>
                              setUserFilterList((pre) => ({
                                ...pre,
                                uploadedBySearch: e.target.value,
                              }))
                            // setUploadedBySearch(e.target.value)
                          }
                          // value={uploadedBySearch}
                          value={userFilterList.uploadedBySearch}
                        />
                      </Box>
                    )}
                    {loading.filterLoader ? (
                      <Typography
                        component={'div'}
                        sx={{
                          // backdropFilter: 'blur(3.5px) !important',
                          left: '50%',
                          position: 'absolute',
                          top: '50%',
                          transform: 'translate(-50%, -50%)',
                          zIndex: 1500,
                        }}>
                        <CircularProgress sx={{ color: '#bf0000' }} size={40} />
                      </Typography>
                    ) : (
                      <Box
                        sx={{
                          '&::-webkit-scrollbar': {
                            width: '5px', // Adjust the width as needed
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'rgba(0, 0, 0, 0.3)', // Customize color
                            borderRadius: '4px', // Optional: rounded corners
                          },
                          '&::-webkit-scrollbar-track': {
                            background: 'transparent', // Optional: track color
                          },
                          // borderBottom: '1px solid #e0e0e0',
                          display: 'flex',
                          flexDirection: 'column',
                          // height: '100%',
                          // maxHeight: 280,
                          // adjust for total box size for checkbox -prev -300
                          // maxWidth: '96%',
                          // maxWidth: '96%',
                          maxWidth: '100%',
                          // scroller width
                          // overflowY: 'auto',
                          padding: '16px',
                          paddingTop: '0px !important',
                          // For Firefox
                          // scrollbarColor: 'rgba(0, 0, 0, 0.3) transparent',
                          // scrollbarWidth: 'thin', // For Firefox
                        }}>
                        {userInfo?.roleId === 5 &&
                          (filteredUserList?.length ? (
                            filteredUserList.map((datas, index: number) => {
                              return (
                                // <FormControlLabel
                                //   key={index}

                                //   control={
                                <Typography
                                  component={'div'}
                                  key={index}
                                  className="res-val-checkbox">
                                  <Checkbox
                                    checkedIcon={
                                      <img src={redcheckbox} alt="redcheck" />
                                    }
                                    checked={checkedIds.includes(datas?.id)}
                                    onChange={(e) =>
                                      handleCheckboxChange(
                                        datas?.id,
                                        e?.target?.checked,
                                      )
                                    }
                                    value={datas?.id}
                                  />
                                  <Typography
                                    className="pointer"
                                    component="div"
                                    onClick={(e) =>
                                      handleCheckboxChange(
                                        datas?.id,
                                        !checkedIds.includes(datas?.id),
                                      )
                                    }>
                                    {datas?.firstName && datas?.lastName
                                      ? `${datas?.firstName} ${datas?.lastName}`
                                      : datas?.firstName}
                                  </Typography>
                                </Typography>
                                // }
                                // label=""
                                // label={
                                //   datas?.firstName && datas?.lastName
                                //     ? `${datas?.firstName} ${datas?.lastName}`
                                //     : datas?.firstName
                                // }
                                // />
                              );
                            })
                          ) : (
                            <Typography className="no-record-found">
                              No Records Found
                            </Typography>
                          ))}
                        <Box
                          mt={2}
                          // className="padd-16"
                          className="padd-16"
                          // sx={{ padding: '16px' }}
                          sx={{ padding: '0px !important' }}>
                          <DateRange
                            editableDateInputs={false}
                            onChange={(item) => {
                              // setDateSelection([item.selection]);
                              handleDateChange(item);
                            }}
                            moveRangeOnFirstSelection={false}
                            ranges={
                              isDateSelected
                                ? dateSelection
                                : [
                                    {
                                      endDate: new Date(),
                                      key: 'selection',
                                      startDate: new Date(),
                                    },
                                  ]
                            }
                            rangeColors={['rgb(191 0 0 / 68%)']}
                            locale={enUS} // Pass the locale here
                          />
                          {userFilterList.error && (
                            <FormHelperText
                              className="errorMsg"
                              sx={{
                                paddingLeft: '12px !important',
                              }}>
                              {userFilterList.error}
                            </FormHelperText>
                          )}
                        </Box>
                        <Box className="resume-filter-centered-button-container">
                          <Button
                            disabled={
                              !dateSelection[0].startDate && !checkedIds.length
                            }
                            className="resume-filter-btn"
                            sx={{
                              backgroundColor: '#0a84f5 !important',
                              color: '#ffffff !important',
                            }}
                            onClick={() => {
                              if (
                                !_.isEmpty(params.fromDate) ||
                                !_.isEmpty(params.toDate) ||
                                !_.isEmpty(params.uploadedBy)
                              ) {
                                resetDefaultValue('date');
                              }
                              resetDefaultValue();
                              // setCheckedIds([]);
                              // setDateSelection([
                              //   {
                              //     endDate: new Date(),
                              //     key: 'selection',
                              //     startDate: new Date(),
                              //   },
                              // ]);
                              // setUserFilterList((pre) => ({
                              //   ...pre,
                              //   error: '',
                              // }));
                              // setError('');
                            }}>
                            Reset
                          </Button>
                          <Button
                            disabled={
                              userInfo.roleId === 3
                                ? !isDateSelected
                                : !checkedIds.length &&
                                  !dateSelection[0].startDate
                            }
                            className="resume-filter-btn"
                            // sx={{
                            //   borderRadius: '20px !important',
                            // }}
                            onClick={(e) => {
                              validateDateSelection()
                                .then((res) => {
                                  // console.log(res, 'res');
                                  setParams((pre) => ({
                                    ...pre,
                                    fromDate: dateSelection[0].startDate
                                      ? moment(
                                          dateSelection[0]?.startDate,
                                        ).format('YYYY-MM-DD')
                                      : '',
                                    // fromDate: moment(
                                    //   dateSelection[0]?.startDate,
                                    // ).format('YYYY-MM-DD'),
                                    skip: 0,
                                    // toDate: moment(
                                    //   dateSelection[0]?.endDate,
                                    // ).format('YYYY-MM-DD'),
                                    toDate: dateSelection[0].endDate
                                      ? moment(
                                          dateSelection[0]?.endDate,
                                        ).format('YYYY-MM-DD')
                                      : '',
                                    uploadedBy: checkedIds.length
                                      ? checkedIds.join(',')
                                      : '',
                                  }));
                                  // setOpenFilter(false);
                                  setUserFilterList((pre) => ({
                                    ...pre,
                                    open: false,
                                    uploadedBySearch: '',
                                  }));
                                })
                                .catch((err) => {
                                  // console.log(err, 'err');
                                  setUserFilterList((pre) => ({
                                    ...pre,
                                    error: err,
                                  }));
                                  // setError(err);
                                });
                            }}>
                            Filter
                          </Button>
                        </Box>
                      </Box>
                    )}

                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                      {/* <Button
                              variant="contained"
                              onClick={() => setShowCalendar(!showCalendar)}>
                              Select Date
                            </Button> */}
                    {/* <Box mt={2}>
                              <CalendarPicker
                                date={selectedDate}
                                view={view}
                                onChange={(date) => {
                                  setSelectedDate(date);
                                  // setShowCalendar(false); // Hide calendar after selecting date
                                }}
                                onViewChange={(newView: CalendarPickerView) => {
                                  // console.log('newView->', newView);
                                  setView(newView === 'month' ? 'year' : 'day');
                                }}
                                onYearChange={(year) => {
                                  setView('day');
                                }}
                                views={['day', 'month', 'year']}
                    {/* </Box>
                          </Box>
                        </LocalizationProvider>
                      </Box> */}
                  </Drawer>
                </Typography>
              </Typography>
              <TableContainer
                component={Paper}
                className="recruiter-jobpost-table">
                {tableData}
              </TableContainer>
            </Typography>
          </Grid>
        </Grid>
        <Typography component={'div'} className="inner-pop-text" />
        {viewModal.show && (
          <ResumeReview
            visible={viewModal?.show}
            resumeId={viewModal?.resumeId}
            fileId={viewModal?.fileId}
            onClose={(e) => {
              if (e === StatusData.REVIEWED) {
                getResumeValidations();
              }
              setViewModal((pre) => ({
                ...pre,
                fileId: null,
                resumeId: null,
                show: false,
              }));
            }}
          />
        )}
      </Typography>
    );
  },
);
