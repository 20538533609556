import React from 'react';

export default function FlagdropdownIcon({
  color,
  open,
}: {
  color?: any;
  open?: any;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="73"
      height="32"
      fill="none"
      viewBox="0 0 73 32">
      <rect
        width="72"
        height="31"
        x="0.5"
        y="0.5"
        fill={color ? color : 'red'}
        rx="15.5"
      />
      <rect
        width="72"
        height="31"
        x="0.5"
        y="0.5"
        stroke={color ? color : 'red'}
        rx="15.5"
      />
      <path
        fill="#fff"
        d="M25.86 11.056L28.726 7.2a1.418 1.418 0 00.14-1.436 1.345 1.345 0 00-.48-.557 1.263 1.263 0 00-.69-.207H12.345c-.177 0-.352.036-.515.107-.163.07-.311.174-.436.305a1.41 1.41 0 00-.292.456 1.46 1.46 0 00-.102.538v19.188c0 .373.142.73.394.994s.594.412.95.412c.357 0 .7-.148.952-.412.252-.263.393-.62.393-.994V17.11h14.007c.244 0 .483-.072.69-.207.207-.135.374-.328.481-.558a1.419 1.419 0 00-.14-1.435l-2.867-3.853z"
      />
      {open ? (
        <path stroke="#fff" strokeLinecap="round" d="M49 19.5l5-7 5 7" />
      ) : (
        <path
          stroke="#fff"
          strokeLinecap="round"
          d="M59.088 12.63l-5.178 6.869-4.819-7.126"
        />
      )}
    </svg>
  );
}
